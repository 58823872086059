export default {
  data() {
    return {
      strings: {
        IT: {
          gigya_warn_new_access_1:
            "ATTENZIONE: stiamo cambiando la modalità di accesso all'area riservata",
          gigya_warn_new_access_2:
            "Se hai già effettuato la registrazione al nuovo sistema puoi accedere direttamente con la tua e-mail e la tua nuova password qui sotto",
          gigya_warn_new_access_3:
            "Se non hai ancora effettuato la registrazione al nuovo sistema puoi registrarti e accedere <a href='gigya_registration_basf.xhtml?lang=IT' target='_blank'>dalla nuova form di registrazione</a>",
          gigya_warn_new_access_4:
            "Altrimenti, fino al <span style='text-decoration: underline'>30 maggio</span> potrai continuare a collegarti con la tua vecchia username e password qui sotto",
          gigya_must_verify: "Per accedere devi verificare il tuo account!",
          gigya_login_error: "Nome utente o password non validi!",
          gigya_login_hat:
            "Inserisci l'e-mail registrata e la password per accedere",
          gigya_email: "E-mail",
          gigya_email_placeholder: "Inserisci l'indirizzo E-mail",
          gigya_password: "Password",
          gigya_password_placeholder: "Inserisci la password",
          gigya_login_button: "Accedi",
          gigya_reset_pwd_link: "Password dimenticata?",
          gigya_registration_link: "Registrati",
          gigya_reset_pwd_check_mail:
            "Controlla la mail per reimpostare la tua password",
          gigya_reset_pwd_hat:
            "Inserisci l'e-mail in uso per l'accesso ai servizi",
          gigya_confirm_button: "Conferma",
          gigya_create_button: "Crea l'account",
          gigya_recovery_pwd_button: "Recupera password",
          gigya_login_link:
            'Clicca <a href="gigya_login.xhtml">qui</a> per tornare alla pagina di login.',
          gigya_new_pwd_hat:
            "Inserisci la nuova password da usare per l'accesso ai servizi",
          gigya_confirm_password: "Conferma password",
          gigya_confirm_password_placeholder: "Conferma la password",
          gigya_new_pwd_length:
            "La password non rispetta la lunghezza minima: ",
          gigya_new_pwd_match:
            "La password non corrisponde al campo di conferma",
          gigya_new_pwd_ok:
            "La password è stata modificata.<br>Ora puoi accedere con la nuova password.",
          gigya_reset_pwd_invalid_link:
            "Link per il reset non valido.<br>Per favore ripeti la procedura di reset per ricevere un nuovo link.",
          gigya_register_check_mail:
            "Grazie per esserti registrato.<br>Controlla la tua e-mail per attivare il tuo account.",
          gigya_register_hat:
            "Compila i dati per ottenere l'accesso ai servizi",
          gigya_register_empty_mail: "L'indirizzo e-mail è obbligatorio",
          gigya_register_empty_password: "La password è obbligatoria",
          gigya_register_empty_name: "Il nome è obbligatorio",
          gigya_register_empty_surname: "Il cognome è obbligatorio",
          gigya_name: "Nome",
          gigya_name_placeholder: "Inserisci il nome",
          gigya_surname: "Cognome",
          gigya_surname_placeholder: "Inserisci il cognome",
          gigya_valid_email: "E-mail valida",
          gigya_invalid_email: "E-mail non valida",
          gigya_invalid_password: "Password non valida",
          gigya_email_in_use: "E-mail già in uso",
          gigya_mobile_phone: "Cellulare",
          gigya_country: "Nazione",
          gigya_mobile_phone_placeholder: "Inserisci il numero di cellulare",
          gigya_terms:
            'Accettazione dei <a href="#" onclick="terminiServizio()">termini e condizioni del servizio</a>',
          gigya_marketing:
            "Accetto che BASF Italia S.p.A. ('BASF') e le altre affiliate del Gruppo BASF possano contattarmi tramite i canali di comunicazione selezionati allo scopo di informarmi sui prodotti, i servizi e gli eventi di BASF che BASF considera di mio interesse e di condurre sondaggi tra i clienti. Al fine di determinare quali informazioni siano di mio interesse, BASF può raccogliere, analizzare e condividere tra tali società i miei dati personali, informazioni sulle mie esigenze e interessi professionali (compresi quelli derivanti dalle mie interazioni rispetto a queste comunicazioni di marketing e dal mio rapporto commerciale in generale con BASF). Ulteriori dettagli sulla portata del mio consenso e sul mio diritto di revocarlo in qualsiasi momento sono disponibili <a href=\"#\" onclick=\"marketingPrivacy()\">qui</a>. Informazioni sul trattamento e sulla protezione dei dati personali all'interno del Gruppo BASF sono disponibili all'indirizzo <a href='https://www.agro.basf.it/it/Data-privacy.html' target='_blank'>Trattamento dei dati nel Gruppo BASF</a>",
          gigya_auth_error: "Errore di autenticazione",
          gigya_not_allowed:
            "Impossibile proseguire! Non disponi dei necessari permessi per accedere all'applicazione.",
          gigya_expired_pwd:
            "La tua password è scaduta.<br>Per ragioni di sicurezza segui la procedura di ripristino per impostare una nuova password.",
          gigya_pending_verification:
            "Account in attesa di verifica.<br>Controlla la tua e-mail e clicca sul link di attivazione.",
          gigya_verification_response: "Esito dell'attivazione",
          gigya_verification_ok:
            "L'attivazione è stata completata con successo, puoi ora procedere alla pagina di login.",
          gigya_verification_error:
            "L'attivazione non è riuscita, contatta lo staff all'indirizzo <a href=\"mailto:info@horta-srl.com\">info@horta-srl.com</a>",
          gigya_termini: "Termini e condizioni del servizio",
          gigya_privacy: "Informativa sul trattamento dei dati personali",
          "title.header": "Agrigenius Vite GO",
          "errors.errMsg1": "Attenzione",
          "errors.errMsgNoService":
            "Non si dispone di un'utenza valida del servizio. Contatta l'helpdesk.",
          "errors.errMsg2": "Errore di sistema",
          "errors.errMsg3": "Clicca",
          "errors.errMsg4": "qui",
          "errors.errMsg5": "per ritentare il login.",
          "errors.errMsg6":
            "Se il problema persiste contattare l'assistenza tecnica.",
          "errors.errMsg7": "Le credenziali inserite non sono corrette.",
          "errors.errMsg8": "Errore di autenticazione",
          "errors.errMsg9": "Errore di sistema",
          "errors.errMsg10":
            "Se hai dimenticato la password puoi contattarci al seguente indirizzo di posta elettronica: info@horta-srl.com",
          "paginator.indietro": "Indietro",
          "paginator.avanti": "Avanti",
          "paginator.prima": "Prima pagina",
          "paginator.ultima": "Ultima pagina",
          "buttons.saveBtn": "Salva",
          "buttons.undoBtn": "Annulla",
          "buttons.siBtn": "Sì",
          "buttons.noBtn": "No",
          "buttons.cercaBtn": "Cerca",
          "buttons.confirmBtn": "Confermo",
          "buttons.closeBtn": "Chiudi",
          "buttons.accetto": "Accetto",
          "buttons.tornasu": "Torna su",
          "messages.noUp1":
            "Sembra che tu non abbia creato nemmeno un'unità produttiva",
          "messages.noUp2":
            "Per poter usare Agrigenius GO c'è bisogno di almeno un'unità produttiva.",
          "messages.noUp3": "per creare la tua prima unità produttiva.",
          "messages.welcome": "Benvenuto in Agrigenius GO",
          "messages.warn": "Attenzione",
          "messages.warnAddNoProduct":
            "Devi selezionare prima il prodotto da aggiungere",
          "messages.warnAddDuplicateProduct":
            "Il prodotto è già presente nella lista",
          "messages.confirmDeleteAzienda":
            "Sei sicuro di voler cancellare la seguente azienda",
          "messages.confirmDeleteUP":
            "Sei sicuro di voler cancellare la seguente UP",
          "messages.confirmDeleteProdotto":
            "Sei sicuro di voler cancellare il seguente prodotto",
          "messages.saveOK": "Dati salvati correttamente",
          "messages.saveError": "Errore durante il salvataggio dei dati",
          "messages.deleteOk": "Dati cancellati correttamente",
          "messages.deleteError": "Errore durante la cancellazione dei dati",
          "messages.deleteUPError":
            "Errore durante la cancellazione dei dati: ci sono delle Up collegate",
          "messages.noItem": "Nessun elemento trovato",
          "messages.noProducts": "Nessun prodotto presente",
          "messages.noUser": "Devi prima selezionare l'utente",
          "messages.noProvincia": "Devi selezionare prima la provincia",
          "messages.noRegione": "Devi selezionare prima la regione",
          "messages.noCountry": "Devi selezionare prima la nazione",
          "messages.required": "Il valore è obbligatorio",
          "messages.nozero": "Il valore deve essere maggiore di zero",
          "messages.markerSet":
            "Marker già presente, adesso devi spostarlo nella posizione desiderata",
          "messages.okConfirmMsg": "Dati confermati correttamente",
          "messages.errorConfirmMsg": "Errore durante la conferma dei dati",
          "messages.okDuplicaMsg": "Dati duplicati correttamente",
          "messages.errorDuplicaMsg": "Errore durante la duplicazione dei dati",
          "messages.helpMap":
            "E' possibile navigare nella mappa per georeferenziare il vigneto: cliccare sulla mappa per far comparire il marker, spostare il marker trascinandolo con il dito sul punto desiderato, cliccare sul marker per completare l'operazione.",
          "editUPData.titleEdit": "Modifica UP",
          "editUPData.titleNew": "Aggiungi nuova UP",
          "editUPData.titleMap": "Mappa",
          "editUPData.errorMsg":
            "Controlla il form per la presenza dei seguenti errori:",
          "viewUpData.title": "Dettaglio UP",
          "viewUpData.utente": "Utente",
          "viewUpData.azienda": "Azienda",
          "viewUpData.descrizione": "Descrizione",
          "viewUpData.superficie": "Superficie (ha)",
          "viewUpData.nazione": "Nazione",
          "viewUpData.regione": "Regione",
          "viewUpData.provincia": "Provincia",
          "viewUpData.comune": "Comune",
          "viewUpData.stazione": "Stazione meteo",
          "viewUpData.lat": "Latitudine",
          "viewUpData.lng": "Longitudine",
          "viewUpData.sisColt": "Sistema colturale",
          "viewUpData.var": "Varietà",
          "viewUpData.cautelaPE": "Livello di cautela peronospora",
          "viewUpData.presenzaMAN": "Presenza Black-rot",
          "viewUpData.pressioneBot": "Livello presione botrite",
          "loginFormData.username": "Username",
          "loginFormData.password": "Password",
          "loginFormData.lang": "Seleziona lingua",
          "loginFormData.login": "Login",
          "loginFormData.enter": "Entra",
          "loginFormData.it": "Italiano",
          "loginFormData.en": "English",
          "loginFormData.es": "Español",
          "loginFormData.pt": "Português",
          "loginFormData.tr": "Türkçe",
          "loginFormData.fr": "Fran\u00e7ais",
          "loginFormData.warnLogin":
            "Per accedere inserire username e password",
          "upFormData.gestione": "Gestione",
          "upFormData.descrizione": "Descrizione",
          "upFormData.titlePaginator": "Elenco UP",
          "upFormData.stazione": "Stazione meteo",
          "upFormData.comune": "Comune",
          "upFormData.canc": "Cancella",
          "upFormData.funz": "Funzionalità",
          "upFormData.scheda": "Scheda",
          "upFormData.mod": "Modifica",
          "upFormData.freeze": "Conferma dati",
          "upFormData.duplica": "Duplica dati",
          "upFormData.meteo": "Meteo",
          "upFormData.feno": "Sviluppo della pianta",
          "upFormData.malattie": "Malattie",
          "upFormData.insetti": "Insetti",
          "upFormData.fito": "DB Fito",
          "upFormData.prot": "Protezione",
          "upFormData.roc": "ROC",
          "upFormData.elab": "Elaborazione in corso...",
          "upFormData.waiting": "Attendere prego",
          "upFormData.elencoUP": "Elenco UP",
          "azFormData.titlePaginator": "Elenco Aziende:",
          "azFormData.titleView": "Dettagli azienda",
          "azFormData.titleEdit": "Modifica dati",
          "azFormData.titleNew": "Inserisci nuovi dati",
          "azFormData.titleSearch": "Filtra aziende",
          "azFormData.rgs": "Ragione sociale",
          "azFormData.piva": "Partita IVA",
          "azFormData.cf": "Codice fiscale",
          "azFormData.cap": "CAP",
          "azFormData.indirizzo": "Indirizzo",
          "azFormData.email": "Email",
          "azFormData.labelProdotti": "Prodotti preferiti",
          "fasiFenData.title": "Fasi fenologiche",
          "fasiFenData.modDate": "Modifica date",
          "fasiFenData.faseDes": "Fase fenologica",
          "fasiFenData.dataStimata": "Data stimata",
          "fasiFenData.dataUtente": "Data osservata",
          "prodottiPreferitiData.title": "Gestione prodotti preferiti",
          "prodottiPreferitiData.nome": "Prodotto",
          "prodottiPreferitiData.ditta": "Ditta",
          "prodottiPreferitiData.avversita": "Avversità controllate",
          "masterData.masterAziende": "Anagrafiche aziendali",
          "masterData.masterNew": "Nuova UP",
          "masterData.masterFilter": "Filtra dati",
          "masterData.masterCleanFilter": "Pulisci filtri",
          "masterData.masterAttive": "Elenco UP attive",
          "masterData.masterScadute": "Elenco UP scadute",
          "fenologia.labelHeatMapEmFoglie": "Emissione foglie",
          "fenologia.labelHeatMapFasiProd": "Fasi riproduttive",
          "fenologia.labelLegend": "Legenda",
          "fenologia.labelLegendaMeteo": "Grafico meteo",
          "malattie.rischioSecondarie": "Rischio da infezioni secondarie",
          "malattie.evoRisk": "Evoluzione rischio",
          "malattie.peronospora": "Peronospora",
          "malattie.oidio": "Oidio",
          "malattie.botrite": "Botrite",
          "malattie.marciumeNero": "Black-rot",
          "malattie.protezione": "Protezione",
          "malattie.noTrat": "Nessun trattamento eseguito",
          "malattie.siTrat": "Trattamento in",
          "malattie.livelloRischio": "Livello di rischio",
          "malattie.livelloRischioProtezione": "Livello di rischio malattia",
          "malattie.rischioBasso": "Basso",
          "malattie.rischioMedioBasso": "Medio-basso",
          "malattie.rischioMedioAlto": "Medio-alto",
          "malattie.rischioAlto": "Alto",
          "insetti.tignoletta": "Tignoletta",
          "insetti.scafoideo": "Scafoideo",
          "insetti.plano": "Planococco",
          "insetti.gen": "gen.",
          "insetti.crisalidi": "Crisalidi",
          "insetti.larve": "Larve",
          "insetti.uova": "Uova",
          "insetti.adulti": "Adulti",
          "insetti.giovani": "Giovani",
          "insetti.neanidi": "Neanidi",
          "insetti.ninfe": "Ninfe",
          "protezione.prodUsati": "Prodotti usati",
          "protezione.efficacia": "Efficacia",
          "protezione.pioggia": "Pioggia",
          "protezione.botriteFaseA": "fioritura",
          "protezione.botriteFaseB": "pre-chiusura grappoli",
          "protezione.botriteFaseC": "inizio invaiatura",
          "protezione.botriteFaseD": "pre-raccolta/maturazione",
          "grafici.temp": "Temp. (°C)",
          "grafici.rain": "Pioggia (mm)",
          "grafici.bagnatura": "Bagnatura fogliare (h)",
          "pwa.info":
            "L'icona di accesso semplificato ad Agrigenius GO non è ancora stata installata, segui le istruzioni che trovi di seguito.",
          "pwa.link":
            "Segui le istruzioni che trovi cliccando sull'icona del tuo sistema operativo.",
          "pwa.passo1":
            "Clicca il tasto 'condividi' che trovi sulla destra della barra degli indirizzi o in fondo allo schermo.",
          "pwa.passo2":
            "Si aprirà un menu dal quale scegliere l'opzione 'Aggiungi a schermata home'.",
          "pwa.passo3":
            "Il dispositivo chiederà nuovamente conferma alla opzione 'Aggiungi a Home' cliccando la voce 'Aggiungi'.",
          "pwa.passo4":
            "Al termine dell'operazione il programma di navigazione si chiuderà automaticamente a l'icona di accesso al servizio Agrigenius GO sarà disponibili sullo schermo del dispositivo come una qualsiasi APP.",
          "messages.maxUp": "Numero massimo di up raggiunto"
        },
        FR: {
          gigya_warn_new_access_1:
            "ATTENTION : nous changeons le mode d'accès à l'espace réservé",
          gigya_warn_new_access_2:
            "Si vous êtes déjà inscrit au nouveau système, vous pouvez vous connecter directement avec votre e-mail et votre nouveau mot de passe",
          gigya_warn_new_access_3:
            "Si vous n'êtes pas encore inscrit au nouveau système, vous pouvez vous inscrire et vous connecter <a href='gigya_registration_basf.xhtml?lang=FR' target='_blank'>à partir du nouveau formulaire d'inscription</a>",
          gigya_warn_new_access_4:
            "Sinon, jusqu'au <span style='text-decoration: underline'>30 mai</span>, vous pouvez continuer à vous connecter avec votre ancien nom d'utilisateur et votre ancien mot de passe ci-dessous",
          gigya_must_verify:
            "Pour vous connecter vous devez vérifier votre compte !",
          gigya_login_error: "Nom d'utilisateur ou mot de passe invalide !",
          gigya_login_hat:
            "Entrez votre email et votre mot de passe enregistrés pour vous connecter",
          gigya_email: "E-mail",
          gigya_email_placeholder: "Entrer l'adresse e-mail",
          gigya_password: "Mot de passe",
          gigya_password_placeholder: "Entrer le mot de passe",
          gigya_login_button: "Se connecter",
          gigya_reset_pwd_link: "Mot de passe oublié?",
          gigya_registration_link: "S'identifier",
          gigya_reset_pwd_check_mail:
            "Vérifiez votre email pour réinitialiser votre mot de passe",
          gigya_reset_pwd_hat:
            "Entrez l'adresse email que vous utilisez pour accéder aux services",
          gigya_confirm_button: "Confirmation",
          gigya_create_button: "Créer un compte",
          gigya_recovery_pwd_button: "Récupération de mot de passe",
          gigya_login_link:
            'Cliquez <a href="gigya_login.xhtml">ici</a> pour revenir à la page de connexion.',
          gigya_new_pwd_hat:
            "Entrez le nouveau mot de passe à utiliser pour accéder aux services",
          gigya_confirm_password: "Confirmez le mot de passe",
          gigya_confirm_password_placeholder: "Confirmez le mot de passe",
          gigya_new_pwd_length:
            "Le mot de passe ne respecte pas la longueur minimale",
          gigya_new_pwd_match:
            "Le mot de passe ne correspond pas au champ de confirmation",
          gigya_new_pwd_ok:
            "Votre mot de passe a été modifié.<br>Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.",
          gigya_reset_pwd_invalid_link:
            "Lien de réinitialisation invalide.<br>Veuillez répéter le processus de réinitialisation pour recevoir un nouveau lien.",
          gigya_register_check_mail:
            "Merci pour votre inscription.<br>Veuillez vérifier votre courrier électronique pour activer votre compte.",
          gigya_register_hat:
            "Remplissez les détails pour accéder aux services",
          gigya_register_empty_mail: "Adresse e-mail est nécessaire",
          gigya_register_empty_password: "Mot de passe requis",
          gigya_register_empty_name: "Le nom est obligatoire",
          gigya_register_empty_surname: "Le nom de famille est obligatoire",
          gigya_name: "Prénom",
          gigya_name_placeholder: "Entrez votre nom",
          gigya_surname: "Nom",
          gigya_surname_placeholder: "Entrez votre nom de famille",
          gigya_valid_email: "Email valide",
          gigya_invalid_email: "Email invalide",
          gigya_invalid_password: "Mot de passe invalide",
          gigya_email_in_use: "Email déjà utilisé",
          gigya_country: "Pays",
          gigya_mobile_phone: "Portable",
          gigya_mobile_phone_placeholder: "Entrez votre numéro de mobile",
          gigya_terms:
            "Acceptation des <a href='#'>conditions générales de service</a>",
          gigya_marketing:
            "J'accepte que BASF Italia S.p.A. (\"BASF\") et d'autres sociétés affiliées du groupe BASF puissent me contacter via des canaux de communication sélectionnés dans le but de m'informer sur les produits, services et événements BASF que BASF considère comme pouvant m'intéresser et pour mener des enquêtes. entre clients. Afin de déterminer quelles informations m'intéressent, BASF peut collecter, analyser et partager entre ces sociétés mes données personnelles, des informations sur mes besoins et intérêts professionnels (y compris ceux résultant de mes interactions avec ces communications marketing et de mes informations générales). relation commerciale avec BASF.) De plus amples détails sur la portée de mon consentement et mon droit de le retirer à tout moment peuvent être trouvés <a href=\"#\" onclick=\"marketingPrivacy()\">ici< /a> . Des informations sur le traitement et la protection des données personnelles au sein du groupe BASF sont disponibles sur <a href='https://www.agro.basf.it/it/Data-privacy.html' target ='_blank'>Traitement des données au sein du groupe BASF</a>",
          gigya_auth_error: "Erreur d'authentification",
          gigya_not_allowed:
            "Impossible de continuer ! Vous ne disposez pas des autorisations nécessaires pour accéder à l'application.",
          gigya_expired_pwd:
            "Votre mot de passe a expiré.<br>Pour des raisons de sécurité, suivez la procédure de récupération pour définir un nouveau mot de passe.",
          gigya_pending_verification:
            "Compte en attente de vérification.<br>Vérifiez votre messagerie et cliquez sur le lien d'activation.",
          gigya_verification_response: "Résultat de l'activation",
          gigya_verification_ok:
            "L'activation s'est terminée avec succès : vous pouvez maintenant accéder à la page de connexion.",
          gigya_verification_error:
            'L\'activation a échoué : contactez le personnel à <a href="mailto:info@horta-srl.com">info@horta-srl.com</a>',
          gigya_termini: "Conditions générales de service",
          gigya_privacy:
            "Informations sur le traitement des données personnelles",
          "title.header": "Agrigenius Vite GO",
          "errors.errMsg1": "Attention",
          "errors.errMsgNoService":
            "Non si dispone di un'utenza valida del servizio. Contatta l'helpdesk.",
          "errors.errMsg2": "Erreur syst\u00e8me",
          "errors.errMsg3": "Cliquez sur",
          "errors.errMsg4": "ici",
          "errors.errMsg5": "pour r\u00e9essayer de vous connecter",
          "errors.errMsg6":
            "Si le probl\u00e8me persiste, contactez le support technique.",
          "errors.errMsg7":
            "Les informations d'identification saisies sont incorrectes.",
          "errors.errMsg8": "Erreur d'authentification",
          "errors.errMsg9": "Erreur syst\u00e8me",
          "errors.errMsg10":
            "Si vous avez oubli\u00e9 votre mot de passe, vous pouvez nous contacter \u00e0 l'adresse \u00e9lectronique suivante : info@horta-srl.com.",
          "paginator.indietro": "Retour",
          "paginator.avanti": "Suivant",
          "paginator.prima": "Premi\u00e8re page",
          "paginator.ultima": "Derni\u00e8re page",
          "buttons.saveBtn": "Sauvegarder",
          "buttons.undoBtn": "Annuler",
          "buttons.siBtn": "Oui",
          "buttons.noBtn": "Non",
          "buttons.cercaBtn": "Rechercher",
          "buttons.confirmBtn": "Confirmer",
          "buttons.closeBtn": "Fermer",
          "buttons.accetto": "Accept",
          "buttons.tornasu": "Back top",
          "messages.noUp1":
            "Il semble que vous n'avez pas cr\u00e9\u00e9 d'unit\u00e9 de production",
          "messages.noUp2":
            "Pour pouvoir utiliser Agrigenius GO, vous avez besoin d'au moins une unit\u00e9 de production",
          "messages.noUp3":
            "pour cr\u00e9er votre premi\u00e8re unit\u00e9 de production.",
          "messages.welcome": "Bienvenue dans Agrigenius GO",
          "messages.warn": "Attention",
          "messages.warnAddNoProduct":
            "Vous devez d'abord s\u00e9lectionner le produit \u00e0 ajouter",
          "messages.warnAddDuplicateProduct":
            "Ce produit a d\u00e9j\u00e0 \u00e9t\u00e9 s\u00e9lectionn\u00e9",
          "messages.confirmDeleteAzienda":
            "\u00cates-vous s\u00fbr de vouloir supprimer l'entreprise suivante",
          "messages.confirmDeleteUP":
            "Etes-vous s\u00fbr de vouloir supprimer l'UP suivante ?",
          "messages.confirmDeleteProdotto":
            "Etes-vous s\u00fbr de vouloir supprimer le produit suivant",
          "messages.saveOK": "Donn\u00e9es enregistr\u00e9es correctement",
          "messages.saveError": "Erreur lors de la sauvegarde des donn\u00e9es",
          "messages.deleteOk": "Donn\u00e9es correctement supprim\u00e9es",
          "messages.deleteError":
            "Erreur lors de la suppression de donn\u00e9es",
          "messages.deleteUPError":
            "Erreur lors de la suppression des donn\u00e9es : il y a des UP connexes \u00e0 cet enregistrement",
          "messages.noItem": "Aucun \u00e9l\u00e9ment trouv\u00e9",
          "messages.noProducts": "Aucun produit trouv\u00e9",
          "messages.noUser":
            "Vous devez d'abord s\u00e9lectionner l'utilisateur",
          "messages.noProvincia":
            "Vous devez d'abord s\u00e9lectionner le d\u00e9partement",
          "messages.noRegione":
            "Vous devez d'abord s\u00e9lectionner la r\u00e9gion",
          "messages.noCountry": "Vous devez d'abord s\u00e9lectionner le pays",
          "messages.required": "La valeur est obligatoire",
          "messages.nozero":
            "La valeur doit \u00eatre sup\u00e9rieure \u00e0 z\u00e9ro",
          "messages.markerSet":
            "Marqueur d\u00e9j\u00e0 pr\u00e9sent, il faut maintenant le d\u00e9placer \u00e0 la position d\u00e9sir\u00e9e",
          "messages.okConfirmMsg": "Donn\u00e9es confirm\u00e9es",
          "messages.errorConfirmMsg":
            "Erreur lors de la confirmation des donn\u00e9es",
          "messages.okDuplicaMsg": "Donn\u00e9es correctement dupliqu\u00e9es",
          "messages.errorDuplicaMsg":
            "Erreur lors de la duplication des donn\u00e9es",
          "messages.helpMap":
            "Il est possible de naviguer sur la carte pour g\u00e9olocaliser le vignoble : cliquer sur la carte pour faire appara\u00eetre le marqueur, d\u00e9placer le marqueur en le faisant glisser avec le doigt jusqu'au point souhait\u00e9, cliquer sur le marqueur pour terminer l'op\u00e9ration.",
          "editUPData.titleEdit": "Modifier les donn\u00e9es",
          "editUPData.titleNew": "Ajouter une nouvelle UP",
          "editUPData.titleMap": "Carte",
          "editUPData.errorMsg":
            "Veuillez v\u00e9rifier que le formulaire ne contient pas les erreurs suivantes :",
          "viewUpData.title": "D\u00e9tail de l'UP",
          "viewUpData.utente": "Utilisateur",
          "viewUpData.azienda": "Entreprise",
          "viewUpData.descrizione": "Description",
          "viewUpData.superficie": "Surface (ha)",
          "viewUpData.nazione": "Pays",
          "viewUpData.regione": "R\u00e9gion",
          "viewUpData.provincia": "Province",
          "viewUpData.comune": "Municipalit\u00e9",
          "viewUpData.stazione": "Station m\u00e9t\u00e9o",
          "viewUpData.lat": "Latitude",
          "viewUpData.lng": "Longitude",
          "viewUpData.sisColt": "Syst\u00e8me de culture",
          "viewUpData.var": "C\u00e9page",
          "viewUpData.cautelaPE": "Niveau de pr\u00e9caution mildiou",
          "viewUpData.presenzaMAN": "Pr\u00e9sence de black-rot",
          "viewUpData.pressioneBot": "Niveau de pression Botrytis",
          "loginFormData.username": "Nom d'utilisateur :",
          "loginFormData.password": "Mot de passe",
          "loginFormData.lang": "S\u00e9lectionner la langue",
          "loginFormData.login": "Connectez-vous",
          "loginFormData.enter": "Entrez",
          "loginFormData.it": "Italien",
          "loginFormData.en": "Anglais",
          "loginFormData.es": "Espagnol",
          "loginFormData.pt": "Portuguais",
          "loginFormData.tr": "Turc",
          "loginFormData.fr": "Fran\u00e7ais",
          "loginFormData.warnLogin":
            "Pour vous connecter, entrez le nom d'utilisateur et le mot de passe",
          "upFormData.gestione": "Gestion",
          "upFormData.descrizione": "Description",
          "upFormData.titlePaginator": "Liste UP",
          "upFormData.stazione": "Station m\u00e9t\u00e9o",
          "upFormData.comune": "Municipalit\u00e9",
          "upFormData.canc": "Supprimer",
          "upFormData.funz": "Fonctionnalit\u00e9",
          "upFormData.scheda": "Fiche d\u00e9taill\u00e9e",
          "upFormData.mod": "Modifier",
          "upFormData.freeze": "Confirmation des donn\u00e9es",
          "upFormData.duplica": "Donn\u00e9es en double",
          "upFormData.meteo": "M\u00e9t\u00e9o",
          "upFormData.feno": "D\u00e9veloppement de la plante",
          "upFormData.malattie": "Maladies",
          "upFormData.insetti": "Insectes",
          "upFormData.fito": "Produits de protection des plantes",
          "upFormData.prot": "Protection",
          "upFormData.roc": "ROC",
          "upFormData.elab": "Traitement en cours...",
          "upFormData.waiting": "Veuillez patienter",
          "upFormData.elencoUP": "Liste UP",
          "azFormData.titlePaginator": "Annuaire des entreprises :",
          "azFormData.titleView": "Coordonn\u00e9es de l'entreprise",
          "azFormData.titleEdit": "Modifier les donn\u00e9es",
          "azFormData.titleNew": "Entrer de nouvelles donn\u00e9es",
          "azFormData.titleSearch": "Rechercher les entreprises",
          "azFormData.rgs": "Nom de la soci\u00e9t\u00e9",
          "azFormData.piva": "N\u00b0 de TVA",
          "azFormData.cf": "Code fiscal",
          "azFormData.cap": "CODE POSTAL",
          "azFormData.indirizzo": "Adresse",
          "azFormData.email": "E-mail",
          "azFormData.labelProdotti":
            "Produits de protection des plantes pr\u00e9f\u00e9r\u00e9s",
          "fasiFenData.title": "Stades ph\u00e9nologiques",
          "fasiFenData.modDate": "Modifier les dates",
          "fasiFenData.faseDes": "Stade ph\u00e9nologique",
          "fasiFenData.dataStimata": "Date estim\u00e9e",
          "fasiFenData.dataUtente": "Date observ\u00e9e",
          "prodottiPreferitiData.title":
            "Gestion des produits de protection des plantes favoris",
          "prodottiPreferitiData.nome": "Produit",
          "prodottiPreferitiData.ditta": "Entreprise",
          "prodottiPreferitiData.avversita": "Maladies contr\u00f4l\u00e9es",
          "masterData.masterAziende": "Liste des entreprises",
          "masterData.masterNew": "Ajouter une nouvelle UP",
          "masterData.masterFilter": "Filtrer les donn\u00e9es",
          "masterData.masterCleanFilter": "Nettoyage des filtres",
          "masterData.masterAttive": "Liste active des UP",
          "masterData.masterScadute": "Liste des UP p\u00e9rim\u00e9es",
          "fenologia.labelHeatMapEmFoglie": "Formation de feuilles",
          "fenologia.labelHeatMapFasiProd": "Phases de reproduction",
          "fenologia.labelLegend": "L\u00e9gende",
          "fenologia.labelLegendaMeteo": "Graphique m\u00e9t\u00e9o",
          "malattie.rischioSecondarie": "Risque d'infections secondaires",
          "malattie.evoRisk": "\u00c9volution du risque",
          "malattie.peronospora": "Mildiou",
          "malattie.oidio": "O\u00efdium",
          "malattie.botrite": "Botrytis",
          "malattie.marciumeNero": "Black rot",
          "malattie.protezione": "Protection",
          "malattie.noTrat": "Aucun traitement effectu\u00e9",
          "malattie.siTrat": "Traitement dans",
          "malattie.livelloRischio": "Niveau de risque",
          "malattie.livelloRischioProtezione": "Niveau de risque de la maladie",
          "malattie.rischioBasso": "Faible",
          "malattie.rischioMedioBasso": "Moyen-faible",
          "malattie.rischioMedioAlto": "Moyenne-\u00e9lev\u00e9e",
          "malattie.rischioAlto": "Elev\u00e9",
          "insetti.tignoletta": "Lobesia botrana - Eud\u00e9mis",
          "insetti.scafoideo": "Cicadelle verte",
          "insetti.plano": "Planococco",
          "insetti.gen": "Gen.",
          "insetti.crisalidi": "Chrysalides",
          "insetti.larve": "Larves",
          "insetti.uova": "\u0152ufs",
          "insetti.adulti": "Adultes",
          "insetti.giovani": "Jeunes",
          "insetti.neanidi": "N\u00e9anides",
          "insetti.ninfe": "Nymphes",
          "protezione.prodUsati": "Produits utilis\u00e9s",
          "protezione.efficacia": "Efficacit\u00e9",
          "protezione.pioggia": "Pluie",
          "protezione.botriteFaseA": "floraison",
          "protezione.botriteFaseB": "pr\u00e9-fermeture des grappes",
          "protezione.botriteFaseC": "d\u00e9but de la v\u00e9raison",
          "protezione.botriteFaseD": "pr\u00e9-r\u00e9colte/maturation",
          "grafici.temp": "Temp. (\u00b0C)",
          "grafici.rain": "Pluie (mm)",
          "grafici.bagnatura": "Humectation de la feuille (h)",
          "pwa.info":
            "L'ic\u00f4ne d'acc\u00e8s simplifi\u00e9 pour Agrigenius GO n'a pas encore \u00e9t\u00e9 install\u00e9e, veuillez suivre les instructions ci-dessous.",
          "pwa.link":
            "Suivez les instructions en cliquant sur l'ic\u00f4ne correspondant \u00e0 votre syst\u00e8me d'exploitation.",
          "pwa.passo1":
            "Cliquez sur le bouton \"Partager\" \u00e0 droite de la barre d'adresse ou en bas de l'\u00e9cran.",
          "pwa.passo2":
            "Un menu s'ouvre, dans lequel vous pouvez choisir l'option \"Ajouter \u00e0 l'\u00e9cran d'accueil\".",
          "pwa.passo3":
            'L\'appareil vous demandera \u00e0 nouveau de confirmer l\'option "Ajouter \u00e0 l\'accueil" en cliquant sur "Ajouter".',
          "pwa.passo4":
            "A la fin de l'op\u00e9ration, le programme de navigation se fermera automatiquement et l'ic\u00f4ne d'acc\u00e8s au service Agrigenius GO sera disponible sur l'\u00e9cran de l'appareil comme n'importe quelle Appli.",
          "messages.maxUp": "Numero massimo di up raggiunto"
        },
        EN: {
          gigya_warn_new_access_1:
            "PLEASE NOTE: we are changing how you can access the restricted area. ",
          gigya_warn_new_access_2:
            "If you have already registered for the new system you can log in directly with your e-mail and new password",
          gigya_warn_new_access_3:
            "If you have not yet registered for the new system you can register and log in <a href='gigya_registration_basf.xhtml?lang=EN' target='_blank'>from the new registration form</a>",
          gigya_warn_new_access_4:
            "Otherwise, until <span style='text-decoration: underline'>May 30</span> you can continue logging in with your old username and password",
          gigya_must_verify: "To log in you must verify your account!",
          gigya_login_error: "Invalid username or password!",
          gigya_login_hat: "Enter your registered email and password to log in",
          gigya_email: "E-mail",
          gigya_email_placeholder: "Enter email address",
          gigya_password: "Password",
          gigya_password_placeholder: "Enter password",
          gigya_login_button: "Log in",
          gigya_reset_pwd_link: "Forgot password?",
          gigya_registration_link: "Sign up",
          gigya_reset_pwd_check_mail: "Check your email to reset your password",
          gigya_reset_pwd_hat:
            "Enter the email address you use to access the services",
          gigya_confirm_button: "He confirms",
          gigya_create_button: "Create account",
          gigya_recovery_pwd_button: "Password recovery",
          gigya_login_link:
            "Click <a href='gigya_login.xhtml'>here</a> to return to the login page.",
          gigya_new_pwd_hat:
            "Enter the new password to use to access the services",
          gigya_confirm_password: "Confirm password",
          gigya_confirm_password_placeholder: "Confirm Password",
          gigya_new_pwd_length: "The password does not meet the minimum length",
          gigya_new_pwd_match:
            "The password does not match the confirmation field",
          gigya_new_pwd_ok:
            "Your password has been changed.<br>You can now log in with your new password.",
          gigya_reset_pwd_invalid_link:
            "Invalid reset link.<br>Please repeat the reset process to receive a new link.",
          gigya_register_check_mail:
            "Thank you for registering.<br>Please check your email to activate your account.",
          gigya_register_hat:
            "Fill in the details to gain access to the services",
          gigya_register_empty_mail: "Email address is required",
          gigya_register_empty_password: "Password is required",
          gigya_register_empty_name: "The name is mandatory",
          gigya_register_empty_surname: "The surname is mandatory",
          gigya_name: "First name",
          gigya_name_placeholder: "Enter your name",
          gigya_surname: "Surname",
          gigya_surname_placeholder: "Enter your last name",
          gigya_valid_email: "Valid email",
          gigya_invalid_email: "Invalid email",
          gigya_invalid_password: "Invalid password",
          gigya_email_in_use: "Email already in use",
          gigya_mobile_phone: "Mobile phone",
          gigya_country: "Country",
          gigya_mobile_phone_placeholder: "Enter your mobile number",
          gigya_terms:
            "Acceptance of the <a href='#'>terms and conditions of service</a>",
          gigya_marketing:
            "I agree that BASF Italia S.p.A. ('BASF') and other affiliates of the BASF Group may contact me via selected communication channels for the purpose of informing me about BASF products, services and events that BASF considers of interest to me and to conduct surveys among customers. In order to determine what information is of interest to me, BASF may collect, analyze and share among these companies my personal data, information about my professional needs and interests (including those resulting from my interactions with these marketing communications and my general business relationship with BASF.) Further details on the scope of my consent and my right to withdraw it at any time can be found <a href=\"#\" onclick=\"marketingPrivacy()\">here< /a>. Information on the processing and protection of personal data within the BASF Group is available at <a href='https://www.agro.basf.it/it/Data-privacy.html' target ='_blank'>Data processing in the BASF Group</a>",
          gigya_auth_error: "Authentication Error",
          gigya_not_allowed:
            "Unable to continue! You do not have the necessary permissions to access the application.",
          gigya_expired_pwd:
            "Your password has expired.<br>For security reasons, follow the recovery procedure to set a new password.",
          gigya_pending_verification:
            "Account awaiting verification.<br>Check your email and click the activation link.",
          gigya_verification_response: "Activation result",
          gigya_verification_ok:
            "Activation was completed successfully: you can now proceed to the login page.",
          gigya_verification_error:
            'Activation failed: contact the staff at <a href="mailto:info@horta-srl.com">info@horta-srl.com</a>',
          gigya_termini: "Terms and conditions of service",
          gigya_privacy: "Information on the processing of personal data",
          "title.header": "Agrigenius Vite GO",
          "errors.errMsg1": "Warning",
          "errors.errMsgNoService":
            "Non si dispone di un'utenza valida del servizio. Contatta l'helpdesk.",
          "errors.errMsg2": "System error",
          "errors.errMsg3": "Click",
          "errors.errMsg4": "here",
          "errors.errMsg5": "to retry login.",
          "errors.errMsg6":
            "If the problem persists, please contact the support",
          "errors.errMsg7": "You supplied an invalid username and/or password.",
          "errors.errMsg8": "Login error",
          "errors.errMsg9": "System error",
          "errors.errMsg10":
            "If you forgot the password please write to: info@horta-srl.com",
          "paginator.indietro": "Back",
          "paginator.avanti": "Forward",
          "paginator.prima": "First page",
          "paginator.ultima": "Last page",
          "buttons.saveBtn": "Save",
          "buttons.undoBtn": "Undo",
          "buttons.siBtn": "Yes",
          "buttons.noBtn": "No",
          "buttons.cercaBtn": "Search",
          "buttons.confirmBtn": "Yes, I am sure",
          "buttons.closeBtn": "Close",
          "buttons.accetto": "Accept",
          "buttons.tornasu": "Back top",
          "messages.noUp1":
            "Sembra che tu non abbia creato nemmeno un'unit\u00e0 produttiva",
          "messages.noUp2":
            "Per poter usare Agrigenius GO c'\u00e8 bisogno di almeno un'unit\u00e0 produttiva.",
          "messages.noUp3": "per creare la tua prima unit\u00e0 produttiva.",
          "messages.welcome": "Welcome in Agrigenius GO",
          "messages.warn": "Warning",
          "messages.warnAddNoProduct":
            "First you have to select the product to add",
          "messages.warnAddDuplicateProduct":
            "This product has been already selected",
          "messages.confirmDeleteAzienda":
            "Are you sure you want to delete the following company",
          "messages.confirmDeleteUP":
            "Are you sure you want to delete the following CU",
          "messages.confirmDeleteProdotto":
            "Are you sure you want to delete the following product",
          "messages.saveOK": "Data saved correctly",
          "messages.saveError": "Error during saving data",
          "messages.deleteOk": "Data deleted correctly",
          "messages.deleteError": "Error during deleting data",
          "messages.deleteUPError":
            "Error during deleting data: there are some CU connected to this record",
          "messages.noItem": "No items found",
          "messages.noProducts": "No product found",
          "messages.noUser": "First you have to select the customer",
          "messages.noProvincia": "First you have to select the province",
          "messages.noRegione": "First you have to select the region",
          "messages.noCountry": "First you have to select the country",
          "messages.required": "The value is mandatory",
          "messages.nozero": "The value must be greater then zero",
          "messages.markerSet":
            "The marker is already present, now you have to move it to the desired position",
          "messages.okConfirmMsg": "Data confirmed correctly.",
          "messages.errorConfirmMsg": "Error during confirmation data",
          "messages.okDuplicaMsg": "Data duplicated correctly.",
          "messages.errorDuplicaMsg": "Error during duplication data",
          "messages.helpMap":
            "It is possible to navigate on the map to georeference the vineyard: click on the map to make the marker appear, move the marker by pulling it with your finger to the desired point, click on the marker to complete the operation.",
          "editUPData.titleEdit": "Edit data",
          "editUPData.titleNew": "Add CU",
          "editUPData.titleMap": "Map",
          "editUPData.errorMsg": "Check form for errors:",
          "viewUpData.title": "CU detail",
          "viewUpData.utente": "Customer",
          "viewUpData.azienda": "Company",
          "viewUpData.descrizione": "Description",
          "viewUpData.superficie": "Surface (ha)",
          "viewUpData.nazione": "Country",
          "viewUpData.regione": "Region",
          "viewUpData.provincia": "Province",
          "viewUpData.comune": "Municipality",
          "viewUpData.stazione": "Weather station",
          "viewUpData.lat": "Latitude",
          "viewUpData.lng": "Longitude",
          "viewUpData.sisColt": "Farming system",
          "viewUpData.var": "Grapevine cultivar",
          "viewUpData.cautelaPE": "Downy mildew caution level",
          "viewUpData.presenzaMAN": "Black-rot presence",
          "viewUpData.pressioneBot": "Botrytis pressure level",
          "loginFormData.username": "Username",
          "loginFormData.password": "Password",
          "loginFormData.lang": "Select language",
          "loginFormData.login": "Login",
          "loginFormData.enter": "Enter",
          "loginFormData.it": "Italiano",
          "loginFormData.en": "English",
          "loginFormData.es": "Espa\u00f1ol",
          "loginFormData.pt": "Portugu\u00eas",
          "loginFormData.tr": "T\u00fcrk\u00e7e",
          "loginFormData.fr": "French",
          "loginFormData.warnLogin":
            "Enter your username and password to log in",
          "upFormData.gestione": "Management",
          "upFormData.descrizione": "Description",
          "upFormData.titlePaginator": "CU list",
          "upFormData.stazione": "Weather station",
          "upFormData.comune": "Municipality",
          "upFormData.canc": "Delete",
          "upFormData.funz": "Functionality",
          "upFormData.scheda": "View detail",
          "upFormData.mod": "Edit data",
          "upFormData.freeze": "Confirm data",
          "upFormData.duplica": "Copy data",
          "upFormData.meteo": "Weather",
          "upFormData.feno": "Plant development",
          "upFormData.malattie": "Diseases",
          "upFormData.insetti": "Pests",
          "upFormData.fito": "Plant protection products",
          "upFormData.prot": "Protection",
          "upFormData.roc": "Field book",
          "upFormData.elab": "Loading...",
          "upFormData.waiting": "Waiting please",
          "upFormData.elencoUP": "CU list",
          "azFormData.titlePaginator": "Companies list:",
          "azFormData.titleView": "Details of company",
          "azFormData.titleEdit": "Edit data",
          "azFormData.titleNew": "Insert new data",
          "azFormData.titleSearch": "Search companies",
          "azFormData.rgs": "Company name",
          "azFormData.piva": "VAT number",
          "azFormData.cf": "Fiscale code",
          "azFormData.cap": "Zip code",
          "azFormData.indirizzo": "Address",
          "azFormData.email": "Email",
          "azFormData.labelProdotti": "Preferred plant protection products",
          "fasiFenData.title": "Phenological stages",
          "fasiFenData.modDate": "Edit date",
          "fasiFenData.faseDes": "Phenological stage",
          "fasiFenData.dataStimata": "Estimated data",
          "fasiFenData.dataUtente": "Observed data",
          "prodottiPreferitiData.title": "Preferred plant protection products",
          "prodottiPreferitiData.nome": "Product",
          "prodottiPreferitiData.ditta": "Company",
          "prodottiPreferitiData.avversita": "Controlled diseases",
          "masterData.masterAziende": "Companies list",
          "masterData.masterNew": "Add CU",
          "masterData.masterFilter": "Filter data",
          "masterData.masterCleanFilter": "Clean filters",
          "masterData.masterAttive": "Active CU list",
          "masterData.masterScadute": "Expired CU list",
          "fenologia.labelHeatMapEmFoglie": "Leaf emission",
          "fenologia.labelHeatMapFasiProd": "Reproductive phases",
          "fenologia.labelLegend": "Legend",
          "fenologia.labelLegendaMeteo": "Weather chart",
          "malattie.rischioSecondarie": "Rischio da infezioni secondarie",
          "malattie.evoRisk": "Risk evolution",
          "malattie.peronospora": "Downy mildew",
          "malattie.oidio": "Powdery mildew",
          "malattie.botrite": "Botritys",
          "malattie.marciumeNero": "Black rot",
          "malattie.protezione": "Protection",
          "malattie.noTrat": "No treatment performed",
          "malattie.siTrat": "Treatment in",
          "malattie.livelloRischio": "Risk level",
          "malattie.livelloRischioProtezione": "Risk level disease",
          "malattie.rischioBasso": "Low",
          "malattie.rischioMedioBasso": "Medium-low",
          "malattie.rischioMedioAlto": "Medium-high",
          "malattie.rischioAlto": "High",
          "insetti.tignoletta": "Lobesia",
          "insetti.scafoideo": "Grapevine leafhopper",
          "insetti.plano": "Planococcus",
          "insetti.gen": "gen.",
          "insetti.crisalidi": "Crisalidi",
          "insetti.larve": "Larvas",
          "insetti.uova": "Eggs",
          "insetti.adulti": "Adults",
          "insetti.giovani": "Juvenile",
          "insetti.neanidi": "Neanides",
          "insetti.ninfe": "Nymphs",
          "protezione.prodUsati": "Used products",
          "protezione.efficacia": "Efficacy",
          "protezione.pioggia": "Rain",
          "protezione.botriteFaseA": "flowering",
          "protezione.botriteFaseB": "pre-bunch closure",
          "protezione.botriteFaseC": "veraison",
          "protezione.botriteFaseD": "pre-harvest/maturation",
          "grafici.temp": "Temp. (\u00b0C)",
          "grafici.rain": "Rain (mm)",
          "grafici.bagnatura": "Leaf wetness (h)",
          "pwa.info":
            "The simplified access icon for Agrigenius GO has not yet been installed, please follow the instructions below.",
          "pwa.link":
            "Follow the instructions by clicking on the icon for your operating system.",
          "pwa.passo1":
            "Click the 'Share' button on the right of the address bar or at bottom of the screen.",
          "pwa.passo2": "Choose the 'Add to home screen' option from the menu.",
          "pwa.passo3":
            "The device will ask again for confirmation of the 'Add to Home' option by clicking on 'Add'.",
          "pwa.passo4":
            "The Agrigenius GO icon will be available on the device screen like any other APP",
          "messages.maxUp": "Numero massimo di up raggiunto"
        },
        PT: {
          gigya_warn_new_access_1:
            "ATENÇÃO: estamos a alterar o modo de acesso à área reservada",
          gigya_warn_new_access_2:
            "Se já se registou no novo sistema, pode iniciar sessão diretamente com o seu e-mail e a sua nova palavra-passe",
          gigya_warn_new_access_3:
            "Se ainda não se registou no novo sistema, pode registar-se e iniciar sessão <a href='gigya_registration_basf.xhtml?lang=PT' target='_blank'>a partir do novo formulário de registo</a>",
          gigya_warn_new_access_4:
            "Caso contrário, até <span style='text-decoration: underline'>30 de maio</span>, pode continuar a iniciar sessão com o seu nome de utilizador e senha antigos",
          gigya_must_verify: "Para fazer login você deve verificar sua conta!",
          gigya_login_error: "Nome de usuário ou senha inválidos!",
          gigya_login_hat:
            "Digite seu e-mail e senha cadastrados para fazer login",
          gigya_email: "E-mail",
          gigya_email_placeholder: "Insira o endereço de e-mail",
          gigya_password: "Senha",
          gigya_password_placeholder: "Digite a senha",
          gigya_login_button: "Conecte-se",
          gigya_reset_pwd_link: "Esqueceu sua senha?",
          gigya_registration_link: "Entrar",
          gigya_reset_pwd_check_mail:
            "Verifique seu e-mail para redefinir sua senha",
          gigya_reset_pwd_hat:
            "Insira o endereço de e-mail que você usa para acessar os serviços",
          gigya_confirm_button: "Ele confirma",
          gigya_create_button: "Criar uma conta",
          gigya_recovery_pwd_button: "Recuperação de senha",
          gigya_login_link:
            'Clique <a href="gigya_login.xhtml">aqui</a> para retornar à página de login.	',
          gigya_new_pwd_hat:
            "Digite a nova senha a ser usada para acessar os serviços",
          gigya_confirm_password: "Confirme sua senha",
          gigya_confirm_password_placeholder: "Confirme sua senha",
          gigya_new_pwd_length: "A senha não atende ao comprimento mínimo",
          gigya_new_pwd_match:
            "A senha não corresponde ao campo de confirmação",
          gigya_new_pwd_ok:
            "Sua senha foi alterada.<br>Agora você pode fazer login com sua nova senha.",
          gigya_reset_pwd_invalid_link:
            "Link de redefinição inválido.<br>Repita o processo de redefinição para receber um novo link.",
          gigya_register_check_mail:
            "Obrigado por se registrar.<br>Verifique seu e-mail para ativar sua conta.",
          gigya_register_hat: "Preencha os dados para ter acesso aos serviços",
          gigya_register_empty_mail: "É necessário um endereço de e-mail",
          gigya_register_empty_password: "Senha requerida",
          gigya_register_empty_name: "O nome é obrigatório",
          gigya_register_empty_surname: "O sobrenome é obrigatório",
          gigya_name: "Primeiro nome",
          gigya_name_placeholder: "Digite seu nome",
          gigya_surname: "Sobrenome",
          gigya_surname_placeholder: "Digite seu sobrenome",
          gigya_valid_email: "Email válido",
          gigya_invalid_email: "E-mail inválido",
          gigya_invalid_password: "Senha inválida",
          gigya_email_in_use: "Email já em uso",
          gigya_mobile_phone: "Celular",
          gigya_mobile_phone_placeholder: "Digite seu número de celular",
          gigya_country: "País",
          gigya_terms:
            'Aceitação dos <a href="#">termos e condições de serviço</a>',
          gigya_marketing:
            "Concordo que a BASF Italia S.p.A. ('BASF') e outras afiliadas do Grupo BASF possam entrar em contato comigo através de canais de comunicação selecionados com a finalidade de me informar sobre produtos, serviços e eventos da BASF que a BASF considere de interesse para mim e para realizar pesquisas entre os clientes. Para determinar quais informações são de meu interesse, a BASF poderá coletar, analisar e compartilhar entre essas empresas meus dados pessoais, informações sobre minhas necessidades e interesses profissionais (incluindo aqueles resultantes de minhas interações com essas comunicações de marketing e minhas informações gerais relacionamento comercial com a BASF.) Mais detalhes sobre o escopo do meu consentimento e meu direito de revogá-lo a qualquer momento podem ser encontrados <a href=\"#\" onclick=\"marketingPrivacy()\">aqui</a> . Informações sobre o processamento e proteção de dados pessoais dentro do Grupo BASF estão disponíveis em <a href='https://www.agro.basf.it/it/Data-privacy.html' target ='_blank'>Processamento de dados no Grupo BASF</a>",
          gigya_auth_error: "Erro de autenticação",
          gigya_not_allowed:
            "Não é possível continuar! Você não tem as permissões necessárias para acessar o aplicativo.",
          gigya_expired_pwd:
            "Sua senha expirou.<br>Por motivos de segurança, siga o procedimento de recuperação para definir uma nova senha.",
          gigya_pending_verification:
            "Conta aguardando verificação.<br>Verifique seu e-mail e clique no link de ativação.",
          gigya_verification_response: "Resultado da ativação",
          gigya_verification_ok:
            "A ativação foi concluída com sucesso: agora você pode prosseguir para a página de login.",
          gigya_verification_error:
            'Falha na ativação: entre em contato com a equipe em <a href="mailto:info@horta-srl.com">info@horta-srl.com</a>',
          gigya_termini: "Termos e condições de serviço",
          gigya_privacy: "Informações sobre o tratamento de dados pessoais",
          "title.header": "Agrigenius Vite GO",
          "errors.errMsg1": "Aten\u00e7\u00e3o",
          "errors.errMsgNoService":
            "N\u00e3o tem um nome de utilizador v\u00e1lido para o servi\u00e7o. Contacte o servi\u00e7o de assist\u00eancia.",
          "errors.errMsg2": "Erro do sistema",
          "errors.errMsg3": "Clique",
          "errors.errMsg4": "aqu\u00ed",
          "errors.errMsg5": "para tentar novamente.",
          "errors.errMsg6":
            "Se o problema persistir, entre em contacto com o suporte",
          "errors.errMsg7":
            "A password / user disponibilizados n\u00e3o s\u00e3o v\u00e1lidos.",
          "errors.errMsg8": "Login erro",
          "errors.errMsg9": "Error de sistema",
          "errors.errMsg10":
            "Se esqueceu a password escreva para: info@horta-srl.com",
          "paginator.indietro": "Voltar",
          "paginator.avanti": "Pr\u00f3ximo",
          "paginator.prima": "Primeira p\u00e1gina",
          "paginator.ultima": "\u00daltima p\u00e1gina",
          "buttons.saveBtn": "Salva os dados",
          "buttons.undoBtn": "Cancelar",
          "buttons.siBtn": "Sim",
          "buttons.noBtn": "N\u00e3o",
          "buttons.cercaBtn": "Procurar",
          "buttons.confirmBtn": "Sim, confirmo",
          "buttons.closeBtn": "Fechar",
          "messages.noUp1":
            "Parece que ainda n\u00e3o criou uma \u00fanica unidade de produ\u00e7\u00e3o",
          "messages.noUp2":
            "Para usar o Agrigenius GO, necessita de pelo menos uma unidade de produ\u00e7\u00e3o.",
          "messages.noUp3":
            "para criar a sua primeira unidade de produ\u00e7\u00e3o.",
          "messages.welcome": "Bem-vindo ao Agrigenius Go",
          "messages.warn": "Aten\u00e7\u00e3o",
          "messages.warnAddNoProduct":
            "Em primeiro lugar selecione o produto a adicionar",
          "messages.warnAddDuplicateProduct":
            "Este produto j\u00e1 foi selecionado",
          "messages.confirmDeleteAzienda":
            "Tem a certeza que pretende eliminar esta empresa?",
          "messages.confirmDeleteUP":
            "Tem certeza de que deseja eliminar a seguinte UP?",
          "messages.confirmDeleteProdotto":
            "Tem certeza de que deseja eliminar o seguinte produto?",
          "messages.saveOK": "Dados guardados corretamente",
          "messages.saveError": "Erro ao guardar os dados",
          "messages.deleteOk": "Dados eliminados corretamente",
          "messages.deleteError": "Erro ao eliminar os dados",
          "messages.deleteUPError":
            "Erro ao excluir dados: existem UP ligadas a este registo",
          "messages.noItem": "Nenhum item encontrado",
          "messages.noProducts": "Nenhum produto encontrado",
          "messages.noUser": "Primeiro tem que selecionar o cliente",
          "messages.noProvincia": "Primeiro tem que selcionar a prov\u00edncia",
          "messages.noRegione": "Primeiro tem que selecionar a regi\u00e3o",
          "messages.noCountry": "Primeiro tem que selecionar o pa\u00eds",
          "messages.required": "O valor \u00e9 obrigat\u00f3rio",
          "messages.nozero": "O valor deve ser maior que zero",
          "messages.markerSet":
            "O marcador j\u00e1 est\u00e1 presente, agora \u00e9 necess\u00e1rio mov\u00ea-lo para a posi\u00e7\u00e3o desejada",
          "messages.okConfirmMsg": "Dados confirmados corretamente",
          "messages.errorConfirmMsg":
            "Erro durante a confirma\u00e7\u00e3o dos dados",
          "messages.okDuplicaMsg":
            "Duplica\u00e7\u00e3o conclu\u00ecda com sucesso",
          "messages.errorDuplicaMsg": "Erro ao duplicar dados",
          "messages.helpMap":
            "\u00c9 poss\u00edvel navegar no mapa para georreferenciar a vinha: clique no mapa para aparecer o marcador, mova o marcador com o dedo at\u00e9 ao ponto desejado, clique no marcador para terminar a opera\u00e7\u00e3o.",
          "editUPData.titleEdit": "Editar dados",
          "editUPData.titleNew": "Adicionar UP",
          "editUPData.titleMap": "Mapa",
          "editUPData.errorMsg": "Verifique o formul\u00e1rio para erros:",
          "viewUpData.title": "Detalhe da UP",
          "viewUpData.utente": "Utilizador",
          "viewUpData.azienda": "Empresa",
          "viewUpData.descrizione": "Descri\u00e7\u00e3o",
          "viewUpData.superficie": "Superf\u00edcie (ha)",
          "viewUpData.nazione": "Pa\u00eds",
          "viewUpData.regione": "Regi\u00e3o",
          "viewUpData.provincia": "Estado",
          "viewUpData.comune": "Cidade",
          "viewUpData.stazione": "Esta\u00e7\u00e3o meteorol\u00f3gica",
          "viewUpData.lat": "Latitude",
          "viewUpData.lng": "Longitude",
          "viewUpData.sisColt": "Sistema de cultivo",
          "viewUpData.var": "Variedade",
          "viewUpData.cautelaPE": "N\u00ecvel de press\u00e3o m\u00ecldio",
          "viewUpData.presenzaMAN": "Presen\u00e7a de podrid\u00e3o-negra",
          "viewUpData.pressioneBot":
            "N\u00edvel de press\u00e3o de podrid\u00e3o-cinzenta",
          "loginFormData.username": "Nome de utilizador",
          "loginFormData.password": "Palavra-passe",
          "loginFormData.lang": "Idioma",
          "loginFormData.login": "Login",
          "loginFormData.enter": "Entrar",
          "loginFormData.it": "Italiano",
          "loginFormData.en": "Ingl\u00eas",
          "loginFormData.es": "Espanhol",
          "loginFormData.pt": "Portugu\u00eas",
          "loginFormData.tr": "Turco",
          "loginFormData.fr": "Franc\u00eas",
          "loginFormData.warnLogin":
            "Introduza o seu nome de utilizador e a sua palavra-passe para iniciar sess\u00e3o",
          "upFormData.gestione": "Gest\u00e3o",
          "upFormData.descrizione": "Descri\u00e7\u00e3o",
          "upFormData.titlePaginator": "Lista UP",
          "upFormData.stazione": "Esta\u00e7\u00e3o meteorol\u00f3gica",
          "upFormData.comune": "Cidade",
          "upFormData.canc": "Eliminar",
          "upFormData.funz": "Funcionalidade",
          "upFormData.scheda": "Visualizar ficha",
          "upFormData.mod": "Editar dados",
          "upFormData.freeze": "Confirme dados",
          "upFormData.duplica": "Copiar dados",
          "upFormData.meteo": "Tempo",
          "upFormData.feno": "Desenvolvimento da planta",
          "upFormData.malattie": "Doen\u00e7as",
          "upFormData.insetti": "Pragas",
          "upFormData.fito": "Produtos fitossanit\u00e1rios",
          "upFormData.prot": "Protec\u00e7\u00e3o",
          "upFormData.roc": "ROC",
          "upFormData.elab": "Processamento em curso...",
          "upFormData.waiting": "Por favor aguarde",
          "upFormData.elencoUP": "Lista UP",
          "azFormData.titlePaginator": "Lista de empresas:",
          "azFormData.titleView": "Detalhe da empresa",
          "azFormData.titleEdit": "Editar dados",
          "azFormData.titleNew": "Inserir novo dados",
          "azFormData.titleSearch": "Filtrar dados",
          "azFormData.rgs": "Designa\u00e7\u00e3o social",
          "azFormData.piva": "N\u00famero de contribuinte",
          "azFormData.cf": "C\u00f3digo fiscal",
          "azFormData.cap": "C\u00f3digo postal",
          "azFormData.indirizzo": "Endere\u00e7o",
          "azFormData.email": "Endere\u00e7o de e-mail",
          "azFormData.labelProdotti": "Fitossanit\u00e1rios preferidos",
          "fasiFenData.title": "Estados fenol\u00f3gicos",
          "fasiFenData.modDate": "Alterar data",
          "fasiFenData.faseDes": "Estado fenol\u00f3gico",
          "fasiFenData.dataStimata": "Data estimada",
          "fasiFenData.dataUtente": "Data observada",
          "prodottiPreferitiData.title":
            "Produtos fitossanit\u00e1rios preferidos",
          "prodottiPreferitiData.nome": "Produto",
          "prodottiPreferitiData.ditta": "Empresa",
          "prodottiPreferitiData.avversita":
            "Lista de pragas/doen\u00e7as tratadas",
          "masterData.masterAziende": "Lista de empresas",
          "masterData.masterNew": "Inserir nova UP",
          "masterData.masterFilter": "Filtrar dados",
          "masterData.masterCleanFilter": "Excluir filtros",
          "masterData.masterAttive": "Lista UP ativa",
          "masterData.masterScadute": "Lista UP expirada",
          "fenologia.labelHeatMapEmFoglie": "Sa\u00edda das folhas",
          "fenologia.labelHeatMapFasiProd":
            "Fases de desenvolvimento da cultura",
          "fenologia.labelLegend": "Legenda",
          "fenologia.labelLegendaMeteo": "Meteorologia",
          "malattie.rischioSecondarie":
            "Risco de infec\u00e7\u00f5es secund\u00e1rias",
          "malattie.evoRisk": "Evolu\u00e7\u00e3o do risco",
          "malattie.peronospora": "M\u00ecldio",
          "malattie.oidio": "O\u00ecdio",
          "malattie.botrite": "Podrid\u00e3o cinzenta",
          "malattie.marciumeNero": "Podrid\u00e3o negra",
          "malattie.protezione": "Prote\u00e7\u00e3o",
          "malattie.noTrat": "N\u00e3o foi realizado nenhum tratamento",
          "malattie.siTrat": "Tratamento em",
          "malattie.livelloRischio": "N\u00edvel de risco",
          "malattie.livelloRischioProtezione":
            "N\u00edvel de risco de doen\u00e7a",
          "malattie.rischioBasso": "Baixo",
          "malattie.rischioMedioBasso": "M\u00e9dio baixo",
          "malattie.rischioMedioAlto": "M\u00e9dio-alto",
          "malattie.rischioAlto": "Alto",
          "insetti.tignoletta": "Tra\u00e7a da uva",
          "insetti.scafoideo": "Scaphoideus titanus",
          "insetti.plano": "Cochonilha",
          "insetti.gen": "gen.",
          "insetti.crisalidi": "Pupa",
          "insetti.larve": "Larvas",
          "insetti.uova": "Ovos",
          "insetti.adulti": "Adultos",
          "insetti.giovani": "Juvenis",
          "insetti.neanidi": "Nean\u00eddeos",
          "insetti.ninfe": "Ninfas",
          "protezione.prodUsati": "Produtos utilizados",
          "protezione.efficacia": "Efic\u00e1cia",
          "protezione.pioggia": "Precipita\u00e7\u00e3o",
          "protezione.botriteFaseA": "flora\u00e7\u00e3o",
          "protezione.botriteFaseB": "pr\u00e9-fecho dos cachos",
          "protezione.botriteFaseC": "pintor",
          "protezione.botriteFaseD": "pr\u00e9-colheita/Matura\u00e7\u00e3o",
          "grafici.temp": "Temp. (\u00b0C)",
          "grafici.rain": "Precipita\u00e7\u00e3o (mm)",
          "grafici.bagnatura": "Humecta\u00e7\u00e3o das folhas (h)",
          "pwa.info":
            "O \u00edcone de acesso simplificado ao Agrigenius Go ainda n\u00e3o foi instalado, por favor siga as instru\u00e7\u00f5es abaixo.",
          "pwa.link":
            "Siga a instru\u00e7\u00f5es selecionando o seu sistema operativo.",
          "pwa.passo1":
            "Selecione 'Partilhar' no bot\u00e3o \u00e0 direita da barra de acesso ou na zona inferior do ecr\u00e3.",
          "pwa.passo2":
            "No menu, selecione a op\u00e7\u00e3o 'Adicionar ao ambiente de trabalho'",
          "pwa.passo3":
            "Selecione 'adicionar' para confirmar a adi\u00e7\u00e3o ao ambiente de trabalho",
          "pwa.passo4":
            "O \u00edcone do Agrigenius Go estar\u00e1 dispon\u00edvel no ecr\u00e3 do aparelho como qualquer outra aplica\u00e7\u00e3o.",
          "messages.maxUp": "Numero massimo di up raggiunto"
        },
        ES: {
          gigya_warn_new_access_1:
            "ATENCIÓN: estamos cambiando el modo de acceso al área reservada",
          gigya_warn_new_access_2:
            "Si ya se ha registrado en el nuevo sistema, puede acceder directamente con su correo electrónico y su nueva contraseña",
          gigya_warn_new_access_3:
            "Si aún no se ha registrado en el nuevo sistema, puede registrarse e iniciar sesión <a href='gigya_registration_basf.xhtml?lang=ES' target='_blank'>desde el nuevo formulario de registro</a>",
          gigya_warn_new_access_4:
            "De lo contrario, hasta el <span style='text-decoration: underline'>30 de mayo</span> puede seguir conectándose con su antiguo nombre de usuario y contraseña",
          gigya_must_verify: "¡Para iniciar sesión debes verificar tu cuenta!",
          gigya_login_error: "¡Usuario o contraseña invalido!",
          gigya_login_hat:
            "Ingrese su correo electrónico registrado y contraseña para iniciar sesión",
          gigya_email: "Correo electrónico",
          gigya_email_placeholder:
            "Introducir la dirección de correo electrónico",
          gigya_password: "Contraseña",
          gigya_password_placeholder: "Introducir la contraseña",
          gigya_login_button: "Iniciar sesión",
          gigya_reset_pwd_link: "¿Has olvidado tu contraseña?",
          gigya_registration_link: "Registrarse",
          gigya_reset_pwd_check_mail:
            "Revise su correo electrónico para restablecer su contraseña",
          gigya_reset_pwd_hat:
            "Ingrese la dirección de correo electrónico que utiliza para acceder a los servicios",
          gigya_confirm_button: "Él confirma",
          gigya_create_button: "Crear una cuenta",
          gigya_recovery_pwd_button: "Recuperación de contraseña",
          gigya_login_link:
            'Haga clic <a href="gigya_login.xhtml">aquí</a> para regresar a la página de inicio de sesión.',
          gigya_new_pwd_hat:
            "Ingrese la nueva contraseña a utilizar para acceder a los servicios",
          gigya_confirm_password: "Confirmar Contraseña",
          gigya_confirm_password_placeholder: "Confirmar Contraseña",
          gigya_new_pwd_length:
            "La contraseña no cumple con la longitud mínima",
          gigya_new_pwd_match:
            "La contraseña no coincide con el campo de confirmación",
          gigya_new_pwd_ok:
            "Su contraseña ha sido cambiada.<br>Ahora puede iniciar sesión con su nueva contraseña.",
          gigya_reset_pwd_invalid_link:
            "Enlace de restablecimiento no válido.<br>Repita el proceso de restablecimiento para recibir un nuevo enlace.",
          gigya_register_check_mail:
            "Gracias por registrarse.<br>Por favor revise su correo electrónico para activar su cuenta.",
          gigya_register_hat: "Rellena los datos para acceder a los servicios",
          gigya_register_empty_mail:
            "Se requiere Dirección de correo electrónico",
          gigya_register_empty_password: "Se requiere contraseña",
          gigya_register_empty_name: "El nombre es obligatorio",
          gigya_register_empty_surname: "El apellido es obligatorio",
          gigya_name: "Nombre de pila",
          gigya_name_placeholder: "Introduzca su nombre",
          gigya_surname: "Apellido",
          gigya_surname_placeholder: "Ingresa tu apellido",
          gigya_valid_email: "Email valido",
          gigya_invalid_email: "Contraseña inválida",
          gigya_invalid_password: "Mot de passe invalide",
          gigya_email_in_use: "Correo electrónico ya en uso",
          gigya_mobile_phone: "Teléfono móvil",
          gigya_mobile_phone_placeholder: "Ingrese su numero celular",
          gigya_country: "País",
          gigya_terms:
            "Aceptación de los <a href='#'>términos y condiciones de servicio</a>",
          gigya_marketing:
            "Acepto que BASF Italia S.p.A. ('BASF') y otras filiales del Grupo BASF puedan ponerse en contacto conmigo a través de canales de comunicación seleccionados con el fin de informarme sobre los productos, servicios y eventos de BASF que BASF considere de mi interés y para realizar encuestas. entre clientes. Para determinar qué información es de mi interés, BASF puede recopilar, analizar y compartir entre estas empresas mis datos personales, información sobre mis necesidades e intereses profesionales (incluidos los resultantes de mis interacciones con estas comunicaciones de marketing y mis intereses generales). relación comercial con BASF.) Puede encontrar más detalles sobre el alcance de mi consentimiento y mi derecho a retirarlo en cualquier momento <a href=\"#\" onclick=\"marketingPrivacy()\">aquí< /a> La información sobre el procesamiento y la protección de datos personales dentro del Grupo BASF está disponible en <a href='https://www.agro.basf.it/it/Data-privacy.html' target ='_blank'>Procesamiento de datos en el Grupo BASF</a>",
          gigya_auth_error: "Error de autenticación",
          gigya_not_allowed:
            "¡No se puede continuar! No tienes los permisos necesarios para acceder a la aplicación.",
          gigya_expired_pwd:
            "Su contraseña ha caducado.<br>Por razones de seguridad, siga el procedimiento de recuperación para establecer una nueva contraseña.",
          gigya_pending_verification:
            "Cuenta en espera de verificación.<br>Revise su correo electrónico y haga clic en el enlace de activación.",
          gigya_verification_response: "Resultado de la activación",
          gigya_verification_ok:
            "La activación se completó con éxito: ahora puede proceder a la página de inicio de sesión.",
          gigya_verification_error:
            'Error de activación: contacte con el personal de <a href="mailto:info@horta-srl.com">info@horta-srl.com</a>',
          gigya_termini: "Términos y condiciones de servicio",
          gigya_privacy: "Información sobre el tratamiento de datos personales",
          "title.header": "Agrigenio Vite GO",
          "errors.errMsg1": "Atenci\u00f3n",
          "errors.errMsgNoService":
            "No tienes un nombre de usuario v\u00e1lido para el servicio. Por favor, p\u00f3ngase en contacto con el servicio de asistencia t\u00e9cnica.",
          "errors.errMsg2": "Error de sistema",
          "errors.errMsg3": "Clique",
          "errors.errMsg4": "aqu\u00ed",
          "errors.errMsg5": "para volver a intentar el inicio de sesi\u00f3n",
          "errors.errMsg6":
            "Si el problema persiste, p\u00f3ngase en contacto con el soporte",
          "errors.errMsg7":
            "El nombre de usuario y/o contrase\u00f1a introducidos no son correctos.",
          "errors.errMsg8": "Error de autentificaci\u00f3n",
          "errors.errMsg9": "Error del sistema",
          "errors.errMsg10":
            "Si ha olvidado la contrase\u00f1a, cont\u00e1ctenos a la siguiente direcci\u00f3n de correo electr\u00f3nico: info@horta-srl.com",
          "paginator.indietro": "Volver",
          "paginator.avanti": "Adelante",
          "paginator.prima": "Primera p\u00e1gina",
          "paginator.ultima": "Ultima p\u00e1gina",
          "buttons.saveBtn": "Guardar",
          "buttons.undoBtn": "Deshacer",
          "buttons.siBtn": "S\u00ed",
          "buttons.noBtn": "No",
          "buttons.cercaBtn": "Buscar",
          "buttons.confirmBtn": "S\u00ed, confirmo",
          "buttons.closeBtn": "Cerrar",
          "messages.noUp1":
            "Parece que no se ha creado ninguna unidad productiva.",
          "messages.noUp2":
            "Para poder utilizar Agrigenio GO se necesita al menos una unidad productiva. ",
          "messages.noUp3": "para crear tu primera unidad productiva.",
          "messages.welcome": "Bienvenido a Agrigenio GO",
          "messages.warn": "Atenci\u00f3n",
          "messages.warnAddNoProduct": "Es necesario seleccionar un producto",
          "messages.warnAddDuplicateProduct":
            "Aviso, no se permiten duplicar productos",
          "messages.confirmDeleteAzienda":
            "\u00bfEst\u00e1 seguro de que quiere eliminar las siguientes empresa?",
          "messages.confirmDeleteUP":
            "\u00bfEst\u00e1 seguro de que quiere eliminar las siguientes UP?",
          "messages.confirmDeleteProdotto":
            "\u00bfEst\u00e1 seguro de que quiere eliminar los siguientes producto",
          "messages.saveOK": "Inserci\u00f3n ok",
          "messages.saveError": "Error en la entrada de datos",
          "messages.deleteOk": "Cancelaci\u00f3n ok",
          "messages.deleteError": "Error en la cancelaci\u00f3n de datos",
          "messages.deleteUPError": "Error en la cancelaci\u00f3n de datos",
          "messages.noItem": "No se han encontrado resultados",
          "messages.noProducts": "No se han encontrado resultados",
          "messages.noUser": "Primero debe seleccionar el usuario",
          "messages.noProvincia": "Primero debe seleccionar la provincia",
          "messages.noRegione":
            "Primero debe seleccionar la comunidad aut\u00f3noma",
          "messages.noCountry": "Primero debe seleccionar el pa\u00eds",
          "messages.required": "Valor obligatorio",
          "messages.nozero": "El valor debe ser mayor que cero",
          "messages.markerSet":
            "El marcador ya se encuentra disponible, debe moverlo a la posici\u00f3n deseada",
          "messages.okConfirmMsg": "Congelaci\u00f3n ok",
          "messages.errorConfirmMsg": "Error en congelaci\u00f3n de datos",
          "messages.okDuplicaMsg": "Duplicaci\u00f3n ok",
          "messages.errorDuplicaMsg": "Error en duplicaci\u00f3n de datos",
          "messages.helpMap":
            "Puede moverse por el mapa para georeferenciar el vi\u00f1edo: pulsar sobre el mapa para que aparezca el marcador, mueva el marcador arrastr\u00e1ndolo con el dedo hasta el punto deseado, pulsar sobre el marcador para completar la operaci\u00f3n.",
          "editUPData.titleEdit": "Editar datos",
          "editUPData.titleNew": "Introduzca nuevos datos",
          "editUPData.titleMap": "Mapa",
          "editUPData.errorMsg": "Compruebe los errores",
          "viewUpData.title": "Visualiza ficha",
          "viewUpData.utente": "Usuario",
          "viewUpData.azienda": "Empresa",
          "viewUpData.descrizione": "Descripci\u00f3n",
          "viewUpData.superficie": "Superficie (ha)",
          "viewUpData.nazione": "Pa\u00eds",
          "viewUpData.regione": "Comunidad aut\u00f3noma",
          "viewUpData.provincia": "Provincia",
          "viewUpData.comune": "Municipio",
          "viewUpData.stazione": "Estaci\u00f3n meteorol\u00f3gica",
          "viewUpData.lat": "Latitud",
          "viewUpData.lng": "Longitud",
          "viewUpData.sisColt": "Sistema de cultivo",
          "viewUpData.var": "Variedad",
          "viewUpData.cautelaPE": "Nivel de precauci\u00f3n mildiu",
          "viewUpData.presenzaMAN": "Presencia de black-rot (ro\u00f1a negra)",
          "viewUpData.pressioneBot": "Nivel de presi\u00f3n botrite",
          "loginFormData.username": "Nombre de usuario",
          "loginFormData.password": "Contrase\u00f1a",
          "loginFormData.lang": "Selecciona lengua",
          "loginFormData.login": "Login",
          "loginFormData.enter": "Entra",
          "loginFormData.it": "Italiano",
          "loginFormData.en": "English",
          "loginFormData.es": "Espa\u00f1ol",
          "loginFormData.pt": "Portugu\u00eas",
          "loginFormData.tr": "T\u00fcrk\u00e7e",
          "loginFormData.fr": "Fran\u00e7ais",
          "loginFormData.warnLogin":
            "Para acceder introduzca el nombre de usuario y la contrase\u00f1a",
          "upFormData.gestione": "Configuraci\u00f3n",
          "upFormData.descrizione": "Descripci\u00f3n",
          "upFormData.titlePaginator": "Lista UP",
          "upFormData.stazione": "Estaci\u00f3n meteorol\u00f3gica",
          "upFormData.comune": "Municipio",
          "upFormData.canc": "Eliminar",
          "upFormData.funz": "Funcionalidad",
          "upFormData.scheda": "Visualiza ficha",
          "upFormData.mod": "Editar",
          "upFormData.freeze": "Confirmar",
          "upFormData.duplica": "Duplicar",
          "upFormData.meteo": "El tiempo",
          "upFormData.feno": "Desarrollo vegetal",
          "upFormData.malattie": "Enfermedades",
          "upFormData.insetti": "Plagas",
          "upFormData.fito": "Productos fitosanitarios",
          "upFormData.prot": "Protecci\u00f3n",
          "upFormData.roc": "ROC",
          "upFormData.elab": "Procesamiento en curso...",
          "upFormData.waiting": "Por favor espere",
          "upFormData.elencoUP": "Lista UP",
          "azFormData.titlePaginator": "Lista de empresas:",
          "azFormData.titleView": "Detalles de la empresa",
          "azFormData.titleEdit": "Editar datos",
          "azFormData.titleNew": "Introduzca nuevos datos",
          "azFormData.titleSearch": "Filtrar datos",
          "azFormData.rgs": "Nombre de la empresa",
          "azFormData.piva": "N\u00famero de IVA",
          "azFormData.cf": "C\u00f3digo de impuestos",
          "azFormData.cap": "PAC",
          "azFormData.indirizzo": "Direcci\u00f3n",
          "azFormData.email": "Direcci\u00f3n de correo electr\u00f3nico",
          "azFormData.labelProdotti": "Productos fitosanitarios preferidos",
          "fasiFenData.title": "Estados de crecimiento",
          "fasiFenData.modDate": "Editar fecha",
          "fasiFenData.faseDes": "Estado de crecimiento",
          "fasiFenData.dataStimata": "Fecha estimada",
          "fasiFenData.dataUtente": "Fecha observada",
          "prodottiPreferitiData.title":
            "Lista de productos fitosanitarios preferidos",
          "prodottiPreferitiData.nome": "Producto",
          "prodottiPreferitiData.ditta": "Compa\u00f1\u00eda",
          "prodottiPreferitiData.avversita": "Enfermedades controlada",
          "masterData.masterAziende": "Lista de empresas",
          "masterData.masterNew": "Introduzca nuevos datos",
          "masterData.masterFilter": "Filtrar datos",
          "masterData.masterCleanFilter": "Quitar filtros",
          "masterData.masterAttive": "UP activa",
          "masterData.masterScadute": "UP caducadas",
          "fenologia.labelHeatMapEmFoglie": "Emisi\u00f3n de hojas",
          "fenologia.labelHeatMapFasiProd": "Fases reproductivas",
          "fenologia.labelLegend": "Leyenda",
          "fenologia.labelLegendaMeteo": "El tiempo",
          "malattie.rischioSecondarie": "Riesgo de infecciones secundarias",
          "malattie.evoRisk": "Evoluci\u00f3n del riesgo",
          "malattie.peronospora": "Mildiu",
          "malattie.oidio": "Oidio",
          "malattie.botrite": "Botrytis",
          "malattie.marciumeNero": "Black-rot",
          "malattie.protezione": "Protecci\u00f3n",
          "malattie.noTrat": "Ning\u00fan tratamiento efectuado",
          "malattie.siTrat": "Tratamiento en",
          "malattie.livelloRischio": "Nivel del riesgo",
          "malattie.livelloRischioProtezione": "Nivel del riesgo enfermedades",
          "malattie.rischioBasso": "Bajo",
          "malattie.rischioMedioBasso": "Medio-bajo",
          "malattie.rischioMedioAlto": "Medio-alto",
          "malattie.rischioAlto": "Alto",
          "insetti.tignoletta": "Polilla del racimo",
          "insetti.scafoideo": "Escafoideos",
          "insetti.plano": "Planococcus",
          "insetti.gen": "gen.",
          "insetti.crisalidi": "Cris\u00e1lidas",
          "insetti.larve": "Larvas",
          "insetti.uova": "Huevos",
          "insetti.adulti": "Adultos",
          "insetti.giovani": "J\u00f3venes",
          "insetti.neanidi": "Ne\u00e1nid",
          "insetti.ninfe": "Ninfas",
          "protezione.prodUsati": "Productos usados",
          "protezione.efficacia": "Eficacia",
          "protezione.pioggia": "Lluvia",
          "protezione.botriteFaseA": "floraci\u00f3n",
          "protezione.botriteFaseB": "pre-cierre del racimo",
          "protezione.botriteFaseC": "inicio del envero",
          "protezione.botriteFaseD": "pre-cosecha / maduraci\u00f3n",
          "grafici.temp": "Temp. (\u00b0C)",
          "grafici.rain": "Lluvia (mm)",
          "grafici.bagnatura": "Humectaci\u00f3n foliar (h)",
          "pwa.info":
            "El icono de acceso simplificado a Agrigenius GO a\u00fan no se ha instalado, sigue las instrucciones que te mostramos a continuaci\u00f3n.",
          "pwa.link":
            "Siga las instrucciones haciendo clic en el icono de su sistema operativo.",
          "pwa.passo1":
            'Pulsa el bot\u00f3n "compartir" a la derecha de la barra de direcciones o en la parte inferior de la p\u00e1gina.',
          "pwa.passo2":
            'Se abrir\u00e1 un men\u00fa en el que puede elegir la opci\u00f3n "A\u00f1adir a pantalla de inicio".',
          "pwa.passo3":
            "El dispositivo solicitar\u00e1 la confirmaci\u00f3n de la opci\u00f3n \"A\u00f1adir a Inicio' haciendo clic en 'A\u00f1adir\".",
          "pwa.passo4":
            "Al finalizar la operaci\u00f3n, el programa de navegaci\u00f3n se cerrar\u00e1 autom\u00e1ticamente y el icono de acceso al servicio Agrigenio GO estar\u00e1 disponible en la pantalla del dispositivo como cualquier otra aplicaci\u00f3n.",
          "messages.maxUp": "Numero massimo di up raggiunto"
        },
        TR: {
          gigya_warn_new_access_1:
            "DİKKAT: erişim modunu ayrılmış alana değiştiriyoruz",
          gigya_warn_new_access_2:
            "Yeni sisteme daha önce kayıt olduysanız, aşağıdaki e-posta adresiniz ve yeni şifrenizle doğrudan giriş yapabilirsiniz",
          gigya_warn_new_access_3:
            "Yeni sisteme henüz kayıt olmadıysanız, <a href='gigya_registration_basf.xhtml?lang=TR' target='_blank'>yeni kayıt formundan</a> kayıt olabilir ve giriş yapabilirsiniz",
          gigya_warn_new_access_4:
            "Aksi takdirde, <span style='text-decoration: underline'>30 Mayıs</span> tarihine kadar aşağıdaki eski kullanıcı adı ve şifrenizle giriş yapmaya devam edebilirsiniz",
          gigya_must_verify:
            "Giriş yapmak için hesabınızı doğrulamanız gerekir!",
          gigya_login_error: "Geçersiz kullanıcı adı veya şifre!",
          gigya_login_hat:
            "Giriş yapmak için kayıtlı e-posta adresinizi ve şifrenizi girin",
          gigya_email: "E-posta",
          gigya_email_placeholder: "Email adresini gir",
          gigya_password: "Şifre",
          gigya_password_placeholder: "Parolanı Gir",
          gigya_login_button: "Giriş yapmak",
          gigya_reset_pwd_link: "Parolanızı mı unuttunuz?",
          gigya_registration_link: "Kayıt olmak",
          gigya_reset_pwd_check_mail:
            "Şifrenizi sıfırlamak için e-postanızı kontrol edin",
          gigya_reset_pwd_hat:
            "Hizmetlere erişmek için kullandığınız e-posta adresini girin",
          gigya_confirm_button: "Onayladı",
          gigya_create_button: "Hesap oluşturmak",
          gigya_recovery_pwd_button: "Şifre kurtarma",
          gigya_login_link:
            'Giriş sayfasına dönmek için <a href="gigya_login.xhtml">burayı</a> tıklayın.',
          gigya_new_pwd_hat:
            "Hizmetlere erişmek için kullanılacak yeni şifreyi girin",
          gigya_confirm_password: "Şifreyi Onayla",
          gigya_confirm_password_placeholder: "Şifreyi Onayla",
          gigya_new_pwd_length: "Şifre minimum uzunluğu karşılamıyor",
          gigya_new_pwd_match: "Şifre onay alanıyla eşleşmiyor",
          gigya_new_pwd_ok:
            "Şifreniz değiştirildi.<br>Artık yeni şifrenizle giriş yapabilirsiniz.",
          gigya_reset_pwd_invalid_link:
            "Geçersiz sıfırlama bağlantısı.<br>Yeni bir bağlantı almak için lütfen sıfırlama işlemini tekrarlayın.",
          gigya_register_check_mail:
            "Kaydolduğunuz için teşekkür ederiz.<br>Hesabınızı etkinleştirmek için lütfen e-postanızı kontrol edin.",
          gigya_register_hat:
            "Hizmetlere erişebilmek için ayrıntıları doldurun",
          gigya_register_empty_mail: "E-posta adresi gerekli",
          gigya_register_empty_password: "Şifre gereklidir",
          gigya_register_empty_name: "İsim zorunludur",
          gigya_register_empty_surname: "Soyadı zorunludur",
          gigya_name: "İlk adı",
          gigya_name_placeholder: "Adınızı giriniz",
          gigya_surname: "Soyadı",
          gigya_surname_placeholder: "Soyadınızı giriniz",
          gigya_valid_email: "Geçerli e-posta",
          gigya_invalid_email: "Geçersiz e-posta",
          gigya_invalid_password: "Geçersiz şifre",
          gigya_email_in_use: "E-posta zaten kullanılıyor",
          gigya_mobile_phone: "Cep telefonu",
          gigya_mobile_phone_placeholder: "Telefon numaranızı girin",
          gigya_country: "Ülke",
          gigya_terms: "<a href='#'>Hizmet şart ve koşullarının</a> kabulü",
          gigya_marketing:
            "BASF Italia S.p.A.'nın ('BASF') ve BASF Grubunun diğer iştiraklerinin, BASF'nin ilgimi çektiğini düşündüğü BASF ürünleri, hizmetleri ve etkinlikleri hakkında beni bilgilendirmek ve anketler yürütmek amacıyla seçilen iletişim kanalları aracılığıyla benimle iletişime geçebileceğini kabul ediyorum. BASF, hangi bilgilerin ilgimi çektiğini belirlemek amacıyla kişisel verilerimi, mesleki ihtiyaçlarım ve ilgi alanlarım hakkındaki bilgileri (bu pazarlama iletişimleri ve genel faaliyetlerim ile etkileşimlerimden kaynaklananlar dahil) toplayabilir, analiz edebilir ve bu şirketler arasında paylaşabilir. BASF ile iş ilişkisi.) Onayımın kapsamı ve onu istediğim zaman geri çekme hakkım hakkında daha fazla ayrıntıyı <a href=\"#\" onclick=\"marketingPrivacy()\">burada< /a> bulabilirsiniz. BASF Grubu bünyesinde kişisel verilerin işlenmesi ve korunmasına ilişkin bilgilere <a href='https://www.agro.basf.it/it/Data-privacy.html' target ='_blank'>Veri işleme adresinden ulaşılabilir. BASF Grubunda</a>",
          gigya_auth_error: "Doğrulama hatası",
          gigya_not_allowed:
            "Devam edilemiyor! Uygulamaya erişim için gerekli izinlere sahip değilsiniz.",
          gigya_expired_pwd:
            "Şifrenizin süresi doldu.<br>Güvenlik nedeniyle, yeni bir şifre belirlemek için kurtarma prosedürünü izleyin.",
          gigya_pending_verification:
            "Hesap doğrulanmayı bekliyor.<br>E-postanızı kontrol edin ve etkinleştirme bağlantısını tıklayın.",
          gigya_verification_response: "Etkinleştirme sonucu",
          gigya_verification_ok:
            "Etkinleştirme başarıyla tamamlandı: artık giriş sayfasına geçebilirsiniz.",
          gigya_verification_error:
            'Etkinleştirme başarısız oldu: <a href="mailto:info@horta-srl.com">info@horta-srl.com</a> adresinden personelle iletişime geçin',
          gigya_termini: "Hizmet şartları ve koşulları",
          gigya_privacy: "Kişisel verilerin işlenmesine ilişkin bilgilendirme",
          "title.header": "Agrigenius Vite GO",
          "errors.errMsg1": "Uyarı",
          "errors.errMsgNoService":
            "Non si dispone di un'utenza valida del servizio. Contatta l'helpdesk.",
          "errors.errMsg2": "Sistem hatası",
          "errors.errMsg3": "Tıklamak",
          "errors.errMsg4": "tıklayın",
          "errors.errMsg5": "oturum açmayı yeniden denemek için.",
          "errors.errMsg6":
            "Sorun devam ederse, lütfen bu hataya neden olan eylemlerin sırasını",
          "errors.errMsg7":
            "Geçersiz bir kullanıcı adı ve/veya şifre girdiniz.",
          "errors.errMsg8": "Kimlik denetimi hatası",
          "errors.errMsg9": "Sistem hatası",
          "errors.errMsg10":
            "şifreyi unuttuysanız lütfen şu adrese yazın: info@horta-srl.com",
          "paginator.indietro": "Geri",
          "paginator.avanti": "İleri",
          "paginator.prima": "İlk sayfa",
          "paginator.ultima": "Son sayfa",
          "buttons.saveBtn": "Kaydet",
          "buttons.undoBtn": "Geri al",
          "buttons.siBtn": "Evet",
          "buttons.noBtn": "Hayır",
          "buttons.cercaBtn": "Arama",
          "buttons.confirmBtn": "Evet, işlemini onaylıyorum",
          "buttons.closeBtn": "Kapat",
          "buttons.accetto": "Accept",
          "buttons.tornasu": "Back top",
          "messages.noUp1": "",
          "messages.noUp2": "",
          "messages.noUp3": "",
          "messages.welcome": "Agrigenius GO’ya hoş geldiniz",
          "messages.warn": "Uyarı",
          "messages.warnAddNoProduct":
            "Öncelikle ekleyeceğiniz ürünü seçmeniz gerekir",
          "messages.warnAddDuplicateProduct": "Bu ürün halihazırda seçilmiş",
          "messages.confirmDeleteAzienda":
            "Bu şirketi silmek istediğinizden emin misiniz?",
          "messages.confirmDeleteUP":
            "CU'yu silmek istediğinizden emin misiniz?",
          "messages.confirmDeleteProdotto":
            "Kaydı silmek istediğinizden emin misiniz?",
          "messages.saveOK": "Silme işlemi başarıyla tamamlandı.",
          "messages.saveError": "Kayıt silinirken hata oluştu.",
          "messages.deleteOk": "Veriler doğru şekilde kaydedildi.",
          "messages.deleteError": "Kayıt silinirken hata oluştu.",
          "messages.deleteUPError":
            "Veri silinirken hata oluştu: Bu kayda bağlı bazı CU var",
          "messages.noItem": "öğe bulunamadı",
          "messages.noProducts": "Öğe bulunamadı",
          "messages.noUser": "İlk önce müşteriyi seçmelisiniz",
          "messages.noProvincia": "İlk önce ili seçmelisiniz",
          "messages.noRegione": "İlk önce bölgeyi seçmelisiniz",
          "messages.noCountry": "İlk önce ülkeyi seçmelisiniz",
          "messages.required": "Değer gerekiyor.",
          "messages.nozero": "Değer en az 0 olmalıdır.",
          "messages.markerSet":
            "İşaretleyici halihazırda mevcut, şimdi istediğiniz yere sürükleyin",
          "messages.okConfirmMsg": "Veriler doğru şekilde teyit edildi.",
          "messages.errorConfirmMsg": "Veriler çoğaltılırken hata oluştu",
          "messages.okDuplicaMsg": "Veriler doğru şekilde çoğaltıldı.",
          "messages.errorDuplicaMsg": "Veriler çoğaltılırken hata oluştu.",
          "messages.helpMap":
            "Üzüm bağını harita üzerinde coğrafi olarak işaretlemek mümkündü: haritaya tıklayarak işaretleyicin görünür olmasını sağlayın, parmağınızla istediğiniz noktaya işaretleyiciyi çekin, işaretleyicinin üzerine tıklayarak işlemi tamamlayın",
          "editUPData.titleEdit": "Verileri düzenle",
          "editUPData.titleNew": "CU ekle",
          "editUPData.titleMap": "Haritayı",
          "editUPData.errorMsg": "Hatalar olduğu için formu kontrol edin.",
          "viewUpData.title": "Veri sayfası",
          "viewUpData.utente": "Kullanıcı",
          "viewUpData.azienda": "çiftlik",
          "viewUpData.descrizione": "Açıklama",
          "viewUpData.superficie": "Alan (ha)",
          "viewUpData.nazione": "ülke",
          "viewUpData.regione": "Bölge",
          "viewUpData.provincia": "İl",
          "viewUpData.comune": "Belediye",
          "viewUpData.stazione": "Meteoroloji İstasyonu",
          "viewUpData.lat": "Enlem",
          "viewUpData.lng": "Boylam",
          "viewUpData.sisColt": "Ürün yetiştirme sistemi",
          "viewUpData.var": "çeşit",
          "viewUpData.cautelaPE": "Mildiyo hastalığı dikkat düzeyi",
          "viewUpData.presenzaMAN": "Siyah çürüklük varlığı",
          "viewUpData.pressioneBot": "Botrytis dikkat düzeyi",
          "loginFormData.username": "Kullanıcı adı",
          "loginFormData.password": "Parola",
          "loginFormData.lang": "Dil Seçin",
          "loginFormData.login": "Giriş",
          "loginFormData.enter": "Giriş",
          "loginFormData.it": "Italiano",
          "loginFormData.en": "English",
          "loginFormData.es": "Español",
          "loginFormData.pt": "Português",
          "loginFormData.tr": "Türkçe",
          "loginFormData.fr": "Fran\u00e7ais",
          "loginFormData.warnLogin":
            "Giriş yapmak üzere kullanıcı adınızı ve şifrenizi girin",
          "upFormData.gestione": "Yönetim",
          "upFormData.descrizione": "Açıklama",
          "upFormData.titlePaginator": "CU listesi",
          "upFormData.stazione": "Meteoroloji İstasyonu",
          "upFormData.comune": "Belediye",
          "upFormData.canc": "Silmek",
          "upFormData.funz": "İşlevsellik",
          "upFormData.scheda": "Veri sayfası",
          "upFormData.mod": "Düzenle",
          "upFormData.freeze": "Onaylamak için tıklayın",
          "upFormData.duplica": "Verileri çoğalt",
          "upFormData.meteo": "Hava Durumu",
          "upFormData.feno": "Bitki gelişimi",
          "upFormData.malattie": "Hastalıklar",
          "upFormData.insetti": "Böcekler ve akarlar",
          "upFormData.fito": "Veritabanı bitki koruma ürünleri",
          "upFormData.prot": "Koruma",
          "upFormData.roc": "COR",
          "upFormData.elab": "çalışma devam ediyor...",
          "upFormData.waiting": "Lütfen bekleyin",
          "upFormData.elencoUP": "CU listesi",
          "azFormData.titlePaginator": "şirket listesi:",
          "azFormData.titleView": "şirket bilgilerini göster",
          "azFormData.titleEdit": "Verileri düzenle",
          "azFormData.titleNew": "Yeni veri ekle",
          "azFormData.titleSearch": "Verileri filtrele",
          "azFormData.rgs": "şirket Adı",
          "azFormData.piva": "KDV numarası  ",
          "azFormData.cf": "Mali kod",
          "azFormData.cap": "Posta kodu",
          "azFormData.indirizzo": "Adres",
          "azFormData.email": "Sorumlu kişinin e-posta adresi",
          "azFormData.labelProdotti": "Tercih edilen bitki koruma ürünleri",
          "fasiFenData.title": "Fenolojik evre",
          "fasiFenData.modDate": "Gözlem tarihini düzenle",
          "fasiFenData.faseDes": "Fenolojik evre",
          "fasiFenData.dataStimata": "Tahmini tarih",
          "fasiFenData.dataUtente": "Gözlem tarihi",
          "prodottiPreferitiData.title": "Favori ürün yönetimi",
          "prodottiPreferitiData.nome": "Ürün",
          "prodottiPreferitiData.ditta": "şirket",
          "prodottiPreferitiData.avversita": "Kontrol edilen hastalıklar",
          "masterData.masterAziende": "şirket listesi",
          "masterData.masterNew": "CU ekle",
          "masterData.masterFilter": "Filtrele",
          "masterData.masterCleanFilter": "Filtreleri sil",
          "masterData.masterAttive": "Aktif CU listesi",
          "masterData.masterScadute": "Süresi dolmuş CU listesi",
          "fenologia.labelHeatMapEmFoglie": "Yaprak emiyonu",
          "fenologia.labelHeatMapFasiProd": "Üreme fazları",
          "fenologia.labelLegend": "Harita açıklamaları",
          "fenologia.labelLegendaMeteo": "Hava durumu çizelgesi",
          "malattie.rischioSecondarie":
            "İkincil enfeksiyonlardan kaynaklanan risk",
          "malattie.evoRisk": "Risk gelişimi",
          "malattie.peronospora": "Mildiyö hastalığı",
          "malattie.oidio": "Külleme",
          "malattie.botrite": "Kurşuni küf",
          "malattie.marciumeNero": "Siyah çürüklük",
          "malattie.protezione": "Koruma",
          "malattie.noTrat": "Yürütülen tedavi yok",
          "malattie.siTrat": "Tedavi",
          "malattie.livelloRischio": "Risk seviyesi",
          "malattie.livelloRischioProtezione": "Risk seviyesi hastalık",
          "malattie.rischioBasso": "Düşük",
          "malattie.rischioMedioBasso": "Orta-düşük",
          "malattie.rischioMedioAlto": "Orta-yüksek",
          "malattie.rischioAlto": "Yüksek",
          "insetti.tignoletta": "Salkım güvesi",
          "insetti.scafoideo": "Asma yaprakpiresi",
          "insetti.plano": "Planococcus",
          "insetti.gen": "nesil",
          "insetti.crisalidi": "Pupa",
          "insetti.larve": "Larvalar",
          "insetti.uova": "Yumurtalar",
          "insetti.adulti": "Yetişkinler",
          "insetti.giovani": "Nimf",
          "insetti.neanidi": "Neanidler",
          "insetti.ninfe": "Larvalar",
          "protezione.prodUsati": "Kullanılmış ürünler",
          "protezione.efficacia": "Etkililik",
          "protezione.pioggia": "Yağmur",
          "protezione.botriteFaseA": "Çiçeklenme",
          "protezione.botriteFaseB": "Dalların kapanma öncesi",
          "protezione.botriteFaseC": "Ben düşme",
          "protezione.botriteFaseD": "Hasat/Olgunlaşma öncesi",
          "grafici.temp": "Sıcaklık (°C)",
          "grafici.rain": "Yağmur (mm)",
          "grafici.bagnatura": "Yaprak nemi (h)",
          "pwa.info":
            "Agrigenius GO’ya ait kolay erişim ikonu henüz yüklenmedi, lütfen aşağıdaki talimatları uygulayın",
          "pwa.link":
            "İşletme sisteminize ait ikonun üzerine tıklayın ve talimatları uygulayın",
          "pwa.passo1":
            "Adres çubuğunun sağındaki veya ekranın altındaki ‘Paylaş’ butonuna tıklayın.",
          "pwa.passo2": "Menüden ‘Ana sayfaya ekle’ seçeneğini seçin.",
          "pwa.passo3":
            "“Ekle’ üzerine tıklandığında cihaz ‘Ana Sayfaya Ekle’ seçeneğinin yeniden doğrulanmasını isteyecektir",
          "pwa.passo4":
            "Agrigenius GO ikonu cihaz ekranı üzerinden herhangi bir diğer uygulama gibi erişilebilir olacaktır",
          "messages.maxUp": "Numero massimo di up raggiunto"
        },
        EL: {
          gigya_warn_new_access_1:
            "ΠΡΟΣΟΧΗ: αλλάζουμε τον τρόπο πρόσβασης στην αποκλειστική περιοχή",
          gigya_warn_new_access_2:
            "Εάν έχετε ήδη εγγραφεί στο νέο σύστημα, μπορείτε να συνδεθείτε απευθείας με το e-mail σας και τον νέο σας κωδικό πρόσβασης παρακάτω",
          gigya_warn_new_access_3:
            "Εάν δεν έχετε ακόμη εγγραφεί στο νέο σύστημα, μπορείτε να εγγραφείτε και να συνδεθείτε <a href='gigya_registration_basf.xhtml?lang=EL' target='_blank'>από τη νέα φόρμα εγγραφής</a>",
          gigya_warn_new_access_4:
            "Διαφορετικά, μέχρι τις <span style='text-decoration: underline'>30 Μαΐου</span> μπορείτε να συνεχίσετε να συνδέεστε με το παλιό σας όνομα χρήστη και τον παλιό σας κωδικό πρόσβασης παρακάτω",
          "title.header": "Agrigenius Wine Grapes GO",
          "errors.errMsg1": "Προσοχή",
          "errors.errMsgNoService":
            "Δεν έχετε έγκυρο όνομα χρήστη για την υπηρεσία. Παρακαλούμε επικοινωνήστε με την τεχνική υποστήριξη.",
          "errors.errMsg2": "Σφάλμα συστήματος",
          "errors.errMsg3": "Πατήστε",
          "errors.errMsg4": "εδώ",
          "errors.errMsg5": "για να επαναλάβετε τη σύνδεση.",
          "errors.errMsg6":
            "Εάν το πρόβλημα παραμένει, επικοινωνήστε με την τεχνική υποστήριξη.",
          "errors.errMsg7": "Τα στοιχεία που έχουν εισαχθεί είναι λανθασμένα.",
          "errors.errMsg8": "Σφάλμα εισόδου",
          "errors.errMsg9": "Σφάλμα συστήματος",
          "errors.errMsg10":
            "Εάν έχετε ξεχάσει τον κωδικό πρόσβασής σας, μπορείτε να επικοινωνήσετε μαζί μας στην ακόλουθη διεύθυνση ηλεκτρονικού ταχυδρομείου: info@horta-srl.com",
          "paginator.indietro": "Πίσω",
          "paginator.avanti": "Επόμενο",
          "paginator.prima": "Πρώτη σελίδα",
          "paginator.ultima": "Τελευταία σελίδα",
          "buttons.saveBtn": "Αποθήκευση",
          "buttons.undoBtn": "Ακύρωση",
          "buttons.siBtn": "Ναι",
          "buttons.noBtn": "Όχι",
          "buttons.cercaBtn": "Αναζήτηση",
          "buttons.confirmBtn": "Επιβεβαίωση",
          "buttons.closeBtn": "Κλείσιμο",
          "messages.noUp1":
            "Φαίνεται ότι δεν έχετε δημιουργήσει κάποια Παραγωγική Μονάδα.",
          "messages.noUp2":
            "Για τη χρήση του Agrigenius GO απαιτείται τουλάχιστον μία Παραγωγική Μονάδα.",
          "messages.noUp3":
            "για να δημιουργήσετε την πρώτη σας Παραγωγική Μονάδα.",
          "messages.welcome": "Καλώς ήρθατε στο Agrigenius GO",
          "messages.warn": "Προσοχή",
          "messages.warnAddNoProduct":
            "Πρέπει πρώτα να επιλέξετε το προϊόν που θα προστεθεί",
          "messages.warnAddDuplicateProduct":
            "Το προϊόν είναι ήδη καταχωρημένο",
          "messages.confirmDeleteAzienda":
            "Σίγουρα θέλετε να διαγράψετε την ακόλουθη γεωργική εκμετάλλευση;",
          "messages.confirmDeleteUP":
            "Είστε βέβαιοι ότι θέλετε να διαγράψετε την ακόλουθη ΠΜ;",
          "messages.confirmDeleteProdotto":
            "Είστε σίγουροι ότι θέλετε να διαγράψετε το ακόλουθο προϊόν;",
          "messages.saveOK": "Τα δεδομένα αποθηκεύτηκαν με επιτυχία",
          "messages.saveError": "Σφάλμα κατά την αποθήκευση δεδομένων",
          "messages.deleteOk": "Επιτυχής διαγραφή δεδομένων",
          "messages.deleteError": "Σφάλμα κατά τη διαγραφή δεδομένων",
          "messages.deleteUPError":
            "Σφάλμα κατά τη διαγραφή δεδομένων: υπάρχουν συνδεδεμένες ΠΜ",
          "messages.noItem": "Δε βρέθηκαν δεδομένα",
          "messages.noProducts": "Δεν υπάρχουν προϊόντα",
          "messages.noUser": "Πρέπει πρώτα να επιλέξετε τον χρήστη",
          "messages.noProvincia":
            "Πρέπει πρώτα να επιλέξετε την Περιφερειακή Ενότητα",
          "messages.noRegione": "Πρέπει πρώτα να επιλέξετε την Περιφέρεια",
          "messages.noCountry": "Πρέπει πρώτα να επιλέξετε τη Χώρα",
          "messages.required": "Η τιμή είναι υποχρεωτική",
          "messages.nozero": "Η τιμή πρέπει να είναι μεγαλύτερη από το μηδέν",
          "messages.markerSet":
            "Ο δείκτης υπάρχει ήδη, τώρα πρέπει να τον μετακινήσετε στην επιθυμητή θέση",
          "messages.okConfirmMsg": "Τα δεδομένα βεβαιώθηκαν επιτυχώς",
          "messages.errorConfirmMsg": "Σφάλμα κατά την επιβεβαίωση δεδομένων",
          "messages.okDuplicaMsg": "Επιτυχής αντιγραφή δεδομένων",
          "messages.errorDuplicaMsg": "Σφάλμα κατά την αντιγραφή δεδομένων",
          "messages.helpMap":
            "Είναι δυνατή η πλοήγηση στο χάρτη για τη γεωαναφορά του αμπελώνα: κάντε κλικ στο χάρτη για να εμφανιστεί ο δείκτης, μετακινήστε το δείκτη σύροντάς τον στο επιθυμητό σημείο, κάντε κλικ στο δείκτη για να ολοκληρώσετε τη λειτουργία.",
          "editUPData.titleEdit": "Επεξεργασία ΠΜ",
          "editUPData.titleNew": "Προσθήκη νέας ΠΜ",
          "editUPData.titleMap": "Χάρτης",
          "editUPData.errorMsg": "Ελέγξτε τα πεδία για τυχόν λάθη:",
          "viewUpData.title": "Λεπτομέρειες ΠΜ",
          "viewUpData.utente": "Χρήστης",
          "viewUpData.azienda": "Γεωργική Εκμετάλλευση",
          "viewUpData.descrizione": "Περιγραφή",
          "viewUpData.superficie": "Επιφάνεια (ha)",
          "viewUpData.nazione": "Χώρα",
          "viewUpData.regione": "Περιφέρεια",
          "viewUpData.provincia": "Περιφερειακή Ενότητα",
          "viewUpData.comune": "Δήμος",
          "viewUpData.stazione": "Μετεωρολογικός σταθμός",
          "viewUpData.lat": "Γεωγραφικό πλάτος",
          "viewUpData.lng": "Γεωγραφικό μήκος",
          "viewUpData.sisColt": "Σύστημα διαχείρισης",
          "viewUpData.var": "Ποικιλίες",
          "viewUpData.cautelaPE": "Επίπεδο προειδοποίησης Περονόσπορου",
          "viewUpData.presenzaMAN": "Παρουσία Μαύρης Σήψης",
          "viewUpData.pressioneBot": "Επίπεδο πίεσης Βοτρύτη",
          "loginFormData.username": "Όνομα χρήστη",
          "loginFormData.password": "Κωδικός πρόσβασης",
          "loginFormData.lang": "Επιλέξτε γλώσσα",
          "loginFormData.login": "Σύνδεση",
          "loginFormData.enter": "Είσοδος",
          "loginFormData.it": "Italiano",
          "loginFormData.en": "English",
          "loginFormData.es": "Español",
          "loginFormData.pt": "Português",
          "loginFormData.tr": "Türkçe",
          "loginFormData.fr": "Français",
          "loginFormData.warnLogin":
            "Για να συνδεθείτε, πληκτρολογήστε το όνομα χρήστη και τον κωδικό πρόσβασης",
          "upFormData.gestione": "Διαχείριση",
          "upFormData.descrizione": "Περιγραφή",
          "upFormData.titlePaginator": "Λίστα ΠΜ",
          "upFormData.stazione": "Μετεωρολογικός σταθμός",
          "upFormData.comune": "Δήμος",
          "upFormData.canc": "Διαγραφή",
          "upFormData.funz": "Λειτουργία",
          "upFormData.scheda": "Εμφάνιση λεπτομερειών",
          "upFormData.mod": "Επεξεργασία",
          "upFormData.freeze": "Επιβεβαίωση δεδομένων",
          "upFormData.duplica": "Αντιγραφή δεδομένων",
          "upFormData.meteo": "Καιρός",
          "upFormData.feno": "Ανάπτυξη φυτών",
          "upFormData.malattie": "Ασθένειες",
          "upFormData.insetti": "Έντομα",
          "upFormData.fito": "Βάση δεδομένων ΦΠ",
          "upFormData.prot": "Προστασία",
          "upFormData.roc": "Ημερολόγιο αγρού",
          "upFormData.elab": "Επεξεργασία σε εξέλιξη...",
          "upFormData.waiting": "Παρακαλώ περιμένετε",
          "upFormData.elencoUP": "Λίστα ΠΜ",
          "azFormData.titlePaginator": "Κατάλογος εκμεταλλεύσεων:",
          "azFormData.titleView": "Λεπτομέρειες εκμετάλλευσης",
          "azFormData.titleEdit": "Επεξεργασία δεδομένων",
          "azFormData.titleNew": "Εισαγωγή νέων δεδομένων",
          "azFormData.titleSearch": "Φίλτρα",
          "azFormData.rgs": "Επωνυμία γεωργικής εκμετάλλευσης",
          "azFormData.piva": "ΑΦΜ ",
          "azFormData.cf": "ΑΦΜ",
          "azFormData.cap": "Ταχυδρομικός κώδικας",
          "azFormData.indirizzo": "Διεύθυνση",
          "azFormData.email": "E-mail",
          "azFormData.labelProdotti": "Αγαπημένα φυτοπροστατευτικά προϊόντα",
          "fasiFenData.title": "Φαινολογικά στάδια ",
          "fasiFenData.modDate": "Επεξεργασία ημερομηνίας",
          "fasiFenData.faseDes": "Φαινολογικό στάδιο",
          "fasiFenData.dataStimata": "Εκτιμώμενη ημερομηνία",
          "fasiFenData.dataUtente": "Ημερομηνία παρατήρησης",
          "prodottiPreferitiData.title":
            "Διαχείριση προτιμώμενων φυτοπροστατευτικών προϊόντων",
          "prodottiPreferitiData.nome": "Προϊόν",
          "prodottiPreferitiData.ditta": "Γεωργική Εκμετάλλευση",
          "prodottiPreferitiData.avversita":
            "Ελεγχόμενοι παράγοντες καταπόνησης",
          "masterData.masterAziende": "Λίστα Γεωργικών Εκμεταλλεύσεων ",
          "masterData.masterNew": "Νέα ΠΜ",
          "masterData.masterFilter": "Φίλτρα δεδομένων",
          "masterData.masterCleanFilter": "Καθαρισμός φίλτρων",
          "masterData.masterAttive": "Λίστα ενεργών ΠΜ",
          "masterData.masterScadute": "Προβολή μη ενεργών ΠΜ",
          "fenologia.labelHeatMapEmFoglie": "Έκπτυξη φύλλων",
          "fenologia.labelHeatMapFasiProd": "Αναπαραγωγικά στάδια",
          "fenologia.labelLegend": "Υπόμνημα",
          "fenologia.labelLegendaMeteo": "Γράφημα καιρού",
          "malattie.rischioSecondarie": "Κίνδυνος από δευτερογενείς μολύνσεις",
          "malattie.evoRisk": "Εξέλιξη του κινδύνου",
          "malattie.peronospora": "Περονόσπορος",
          "malattie.oidio": "Ωίδιο",
          "malattie.botrite": "Βοτρύτης",
          "malattie.marciumeNero": "Μαύρη σήψη",
          "malattie.protezione": "Προστασία",
          "malattie.noTrat": "Δεν έγινε καμία εφαρμογή",
          "malattie.siTrat": "Επέμβαση σε",
          "malattie.livelloRischio": "Επίπεδο κινδύνου",
          "malattie.livelloRischioProtezione": "Επίπεδο κινδύνου ασθένειας",
          "malattie.rischioBasso": "Χαμηλή",
          "malattie.rischioMedioBasso": "Μέτρια-χαμηλή",
          "malattie.rischioMedioAlto": "Μέτρια-υψηλή",
          "malattie.rischioAlto": "Υψηλή",
          "insetti.tignoletta": "Ευδεμίδα",
          "insetti.scafoideo": "Τζιτζίκάκι",
          "insetti.plano": "Ψευδόκοκκος",
          "insetti.gen": "γεν.",
          "insetti.crisalidi": "Νύμφες",
          "insetti.larve": "Προνύμφες",
          "insetti.uova": "Αυγά",
          "insetti.adulti": "Ενήλικα",
          "insetti.giovani": "Προνύμφες",
          "insetti.neanidi": "Νύμφες",
          "insetti.ninfe": "Νύμφες",
          "protezione.prodUsati": "Προϊόντα που χρησιμοποιούνται",
          "protezione.efficacia": "Αποτελεσματικότητα",
          "protezione.pioggia": "Βροχή",
          "protezione.botriteFaseA": "ανθοφορία",
          "protezione.botriteFaseB": "πριν το κλείσιμο των ταξικαπριών",
          "protezione.botriteFaseC": "έναρξη περκασμού",
          "protezione.botriteFaseD": "προσυλλεκτικά/ωρίμανση",
          "grafici.temp": "Θερμοκρασία (°C)",
          "grafici.rain": "Βροχόπτωση (mm)",
          "grafici.bagnatura": "Διύγρανση φυλλώματος (ώρες)",
          "pwa.info":
            "Το εικονίδιο απλοποιημένης πρόσβασης για το Agrigenius GO δεν έχει εγκατασταθεί ακόμη, ακολουθήστε τις παρακάτω οδηγίες.",
          "pwa.link":
            "Ακολουθήστε τις οδηγίες κάνοντας κλικ στο εικονίδιο για το λειτουργικό σας σύστημα.",
          "pwa.passo1":
            'Κάντε κλικ στο κουμπί "κοινή χρήση" στα δεξιά της γραμμής διευθύνσεων ή στο κάτω μέρος της οθόνης.',
          "pwa.passo2":
            'Θα ανοίξει ένα μενού από το οποίο μπορείτε να επιλέξετε την επιλογή "Προσθήκη στην αρχική οθόνη".',
          "pwa.passo3":
            'Η συσκευή θα ζητήσει και πάλι επιβεβαίωση της επιλογής "Προσθήκη στην αρχική οθόνη" κάνοντας κλικ στο "Προσθήκη".',
          "pwa.passo4":
            "Στο τέλος της λειτουργίας, το πρόγραμμα πλοήγησης θα κλείσει αυτόματα και το εικονίδιο για την πρόσβαση στην υπηρεσία Agrigenius GO θα είναι διαθέσιμο στην οθόνη της συσκευής όπως κάθε άλλη εφαρμογή.",
          "buttons.tornasu": "Επιστροφή στην αρχή"
        }
      }
    };
  },
  computed: {
    gigyaWarn1: {
      get() {
        return this.strings[this.globalLocale]["gigya_warn_new_access_1"];
      }
    },
    gigyaWarn2: {
      get() {
        return this.strings[this.globalLocale]["gigya_warn_new_access_2"];
      }
    },
    gigyaWarn3: {
      get() {
        return this.strings[this.globalLocale]["gigya_warn_new_access_3"];
      }
    },
    gigyaWarn4: {
      get() {
        return this.strings[this.globalLocale]["gigya_warn_new_access_4"];
      }
    },
    maxUpMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.maxUp"];
      }
    },
    gigyaInvalidPwd: {
      get() {
        return this.strings[this.globalLocale]["gigya_invalid_password"];
      }
    },
    gigyaCrea: {
      get() {
        return this.strings[this.globalLocale]["gigya_create_button"];
      }
    },
    gigyaNome: {
      get() {
        return this.strings[this.globalLocale]["gigya_name"];
      }
    },
    gigyaNomePlaceholder: {
      get() {
        return this.strings[this.globalLocale]["gigya_name_placeholder"];
      }
    },
    gigyaCognome: {
      get() {
        return this.strings[this.globalLocale]["gigya_surname"];
      }
    },
    gigyaCognomePlaceholder: {
      get() {
        return this.strings[this.globalLocale]["gigya_surname_placeholder"];
      }
    },
    gigyaEmail: {
      get() {
        return this.strings[this.globalLocale]["gigya_email"];
      }
    },
    gigyaEmailPlaceholder: {
      get() {
        return this.strings[this.globalLocale]["gigya_email_placeholder"];
      }
    },
    gigyaCell: {
      get() {
        return this.strings[this.globalLocale]["gigya_mobile_phone"];
      }
    },
    gigyaCellPlaceholder: {
      get() {
        return this.strings[this.globalLocale]["gigya_cell_placeholder"];
      }
    },
    gigyaPasswordPlaceholder: {
      get() {
        return this.strings[this.globalLocale]["gigya_password_placeholder"];
      }
    },
    gigyaConfirmPassword: {
      get() {
        return this.strings[this.globalLocale]["gigya_confirm_password"];
      }
    },
    gigyaConfirmPasswordPlaceholder: {
      get() {
        return this.strings[this.globalLocale][
          "gigya_confirm_password_placeholder"
        ];
      }
    },
    gigyaTermini: {
      get() {
        return this.strings[this.globalLocale]["gigya_termini"];
      }
    },
    gigyaInformativa: {
      get() {
        return this.strings[this.globalLocale]["gigya_privacy"];
      }
    },
    gigyaForgetPwd: {
      get() {
        return this.strings[this.globalLocale]["gigya_reset_pwd_link"];
      }
    },
    gigyaAccedi: {
      get() {
        return this.strings[this.globalLocale]["gigya_login_button"];
      }
    },
    gigyaRegistrati: {
      get() {
        return this.strings[this.globalLocale]["gigya_registration_link"];
      }
    },
    gigyaTerms: {
      get() {
        return this.strings[this.globalLocale]["gigya_terms"];
      }
    },
    gigyaMarketing: {
      get() {
        return this.strings[this.globalLocale]["gigya_marketing"];
      }
    },
    headerTitle: {
      get() {
        return this.strings[this.globalLocale]["title.header"];
      }
    },
    errMsgNoService: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsgNoService"];
      }
    },
    noUp1: {
      get() {
        return this.strings[this.globalLocale]["messages.noUp1"];
      }
    },
    noUp2: {
      get() {
        return this.strings[this.globalLocale]["messages.noUp2"];
      }
    },
    noUp3: {
      get() {
        return this.strings[this.globalLocale]["messages.noUp3"];
      }
    },
    welcome: {
      get() {
        return this.strings[this.globalLocale]["messages.welcome"];
      }
    },
    warnLogin: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.warnLogin"];
      }
    },
    labelPasso4: {
      get() {
        return this.strings[this.globalLocale]["pwa.passo4"];
      }
    },
    labelPasso3: {
      get() {
        return this.strings[this.globalLocale]["pwa.passo3"];
      }
    },
    labelPasso2: {
      get() {
        return this.strings[this.globalLocale]["pwa.passo2"];
      }
    },
    labelPasso1: {
      get() {
        return this.strings[this.globalLocale]["pwa.passo1"];
      }
    },
    labelPwaInfo: {
      get() {
        return this.strings[this.globalLocale]["pwa.info"];
      }
    },
    labelPwaLink: {
      get() {
        return this.strings[this.globalLocale]["pwa.link"];
      }
    },
    labelBotriteFaseA: {
      get() {
        return this.strings[this.globalLocale]["protezione.botriteFaseA"];
      }
    },
    labelBotriteFaseB: {
      get() {
        return this.strings[this.globalLocale]["protezione.botriteFaseB"];
      }
    },
    labelBotriteFaseC: {
      get() {
        return this.strings[this.globalLocale]["protezione.botriteFaseC"];
      }
    },
    labelBotriteFaseD: {
      get() {
        return this.strings[this.globalLocale]["protezione.botriteFaseD"];
      }
    },
    labelLegendaMeteo: {
      get() {
        return this.strings[this.globalLocale]["fenologia.labelLegendaMeteo"];
      }
    },
    labelTempChart: {
      get() {
        return this.strings[this.globalLocale]["grafici.temp"];
      }
    },
    labelPioggiaChart: {
      get() {
        return this.strings[this.globalLocale]["grafici.rain"];
      }
    },
    labelBagnaturaChart: {
      get() {
        return this.strings[this.globalLocale]["grafici.bagnatura"];
      }
    },
    rischioBasso: {
      get() {
        return this.strings[this.globalLocale]["malattie.rischioBasso"];
      }
    },
    rischioMedioBasso: {
      get() {
        return this.strings[this.globalLocale]["malattie.rischioMedioBasso"];
      }
    },
    rischioMedioAlto: {
      get() {
        return this.strings[this.globalLocale]["malattie.rischioMedioAlto"];
      }
    },
    rischioAlto: {
      get() {
        return this.strings[this.globalLocale]["malattie.rischioAlto"];
      }
    },
    errMsg1: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg1"];
      }
    },
    errMsg2: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg2"];
      }
    },
    errMsg3: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg3"];
      }
    },
    errMsg4: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg4"];
      }
    },
    errMsg5: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg5"];
      }
    },
    errMsg6: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg6"];
      }
    },
    errMsg7: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg7"];
      }
    },
    errMsg9: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg9"];
      }
    },
    errMsg8: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg8"];
      }
    },
    errMsg10: {
      get() {
        return this.strings[this.globalLocale]["errors.errMsg10"];
      }
    },
    okConfirmMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.okConfirmMsg"];
      }
    },
    errorConfirmMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.errorConfirmMsg"];
      }
    },
    okDuplicaMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.okDuplicaMsg"];
      }
    },
    errorDuplicaMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.errorDuplicaMsg"];
      }
    },
    labelProdProt: {
      get() {
        return this.strings[this.globalLocale]["protezione.prodUsati"];
      }
    },
    labelEfficacia: {
      get() {
        return this.strings[this.globalLocale]["protezione.efficacia"];
      }
    },
    labelPioggia: {
      get() {
        return this.strings[this.globalLocale]["protezione.pioggia"];
      }
    },
    labelTignoletta: {
      get() {
        return this.strings[this.globalLocale]["insetti.tignoletta"];
      }
    },
    labelScafoideo: {
      get() {
        return this.strings[this.globalLocale]["insetti.scafoideo"];
      }
    },
    labelPlano: {
      get() {
        return this.strings[this.globalLocale]["insetti.plano"];
      }
    },
    labelGen: {
      get() {
        return this.strings[this.globalLocale]["insetti.gen"];
      }
    },
    labelCrisalidi: {
      get() {
        return this.strings[this.globalLocale]["insetti.crisalidi"];
      }
    },
    labelLarve: {
      get() {
        return this.strings[this.globalLocale]["insetti.larve"];
      }
    },
    labelUova: {
      get() {
        return this.strings[this.globalLocale]["insetti.uova"];
      }
    },
    labelAdulti: {
      get() {
        return this.strings[this.globalLocale]["insetti.adulti"];
      }
    },
    labelNeanidi: {
      get() {
        return this.strings[this.globalLocale]["insetti.neanidi"];
      }
    },
    labelNinfe: {
      get() {
        return this.strings[this.globalLocale]["insetti.ninfe"];
      }
    },
    labelGiovani: {
      get() {
        return this.strings[this.globalLocale]["insetti.giovani"];
      }
    },
    labelEvo: {
      get() {
        return this.strings[this.globalLocale]["malattie.evoRisk"];
      }
    },
    labelPE: {
      get() {
        return this.strings[this.globalLocale]["malattie.peronospora"];
      }
    },
    labelOI: {
      get() {
        return this.strings[this.globalLocale]["malattie.oidio"];
      }
    },
    labelBOT: {
      get() {
        return this.strings[this.globalLocale]["malattie.botrite"];
      }
    },
    labelMAN: {
      get() {
        return this.strings[this.globalLocale]["malattie.marciumeNero"];
      }
    },
    labelProt: {
      get() {
        return this.strings[this.globalLocale]["malattie.protezione"];
      }
    },
    rischioSecondarie: {
      get() {
        return this.strings[this.globalLocale]["malattie.rischioSecondarie"];
      }
    },
    siTrats: {
      get() {
        return this.strings[this.globalLocale]["malattie.siTrat"];
      }
    },
    noTrats: {
      get() {
        return this.strings[this.globalLocale]["malattie.noTrat"];
      }
    },
    livelloRischioProtezione: {
      get() {
        return this.strings[this.globalLocale][
          "malattie.livelloRischioProtezione"
        ];
      }
    },
    livelloRischio: {
      get() {
        return this.strings[this.globalLocale]["malattie.livelloRischio"];
      }
    },
    labelHeatMapEmFoglie: {
      get() {
        return this.strings[this.globalLocale][
          "fenologia.labelHeatMapEmFoglie"
        ];
      }
    },
    labelHeatMapFasiProd: {
      get() {
        return this.strings[this.globalLocale][
          "fenologia.labelHeatMapFasiProd"
        ];
      }
    },
    labelLegend: {
      get() {
        return this.strings[this.globalLocale]["fenologia.labelLegend"];
      }
    },
    duplicatedProductWarn: {
      get() {
        return this.strings[this.globalLocale][
          "messages.warnAddDuplicateProduct"
        ];
      }
    },
    selectProductWarn: {
      get() {
        return this.strings[this.globalLocale]["messages.warnAddNoProduct"];
      }
    },
    labelTitleAZPaginator: {
      get() {
        return this.strings[this.globalLocale]["azFormData.titlePaginator"];
      }
    },
    labelTitleAZView: {
      get() {
        return this.strings[this.globalLocale]["azFormData.titleView"];
      }
    },
    labelTitleAZEdit: {
      get() {
        return this.strings[this.globalLocale]["azFormData.titleEdit"];
      }
    },
    labelTitleAZNew: {
      get() {
        return this.strings[this.globalLocale]["azFormData.titleNew"];
      }
    },
    labelTitleAZearch: {
      get() {
        return this.strings[this.globalLocale]["azFormData.titleSearch"];
      }
    },
    labelPiva: {
      get() {
        return this.strings[this.globalLocale]["azFormData.piva"];
      }
    },
    labelCF: {
      get() {
        return this.strings[this.globalLocale]["azFormData.cf"];
      }
    },
    labelRGS: {
      get() {
        return this.strings[this.globalLocale]["azFormData.rgs"];
      }
    },
    labelCAP: {
      get() {
        return this.strings[this.globalLocale]["azFormData.cap"];
      }
    },
    labelIndirizzo: {
      get() {
        return this.strings[this.globalLocale]["azFormData.indirizzo"];
      }
    },
    labelEmail: {
      get() {
        return this.strings[this.globalLocale]["azFormData.email"];
      }
    },
    labelListProdotti: {
      get() {
        return this.strings[this.globalLocale]["azFormData.labelProdotti"];
      }
    },
    labelTitleProdotti: {
      get() {
        return this.strings[this.globalLocale]["prodottiPreferitiData.title"];
      }
    },
    labelNomeProdotti: {
      get() {
        return this.strings[this.globalLocale]["prodottiPreferitiData.nome"];
      }
    },
    labelDittaProdotti: {
      get() {
        return this.strings[this.globalLocale]["prodottiPreferitiData.ditta"];
      }
    },
    labelAvversitaProdotti: {
      get() {
        return this.strings[this.globalLocale][
          "prodottiPreferitiData.avversita"
        ];
      }
    },
    labelPressioneBot: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.pressioneBot"];
      }
    },
    labelCerca: {
      get() {
        return this.strings[this.globalLocale]["buttons.cercaBtn"];
      }
    },
    masterAziende: {
      get() {
        return this.strings[this.globalLocale]["masterData.masterAziende"];
      }
    },
    masterNew: {
      get() {
        return this.strings[this.globalLocale]["masterData.masterNew"];
      }
    },
    masterFilter: {
      get() {
        return this.strings[this.globalLocale]["masterData.masterFilter"];
      }
    },
    masterCleanFilter: {
      get() {
        return this.strings[this.globalLocale]["masterData.masterCleanFilter"];
      }
    },
    masterAttive: {
      get() {
        return this.strings[this.globalLocale]["masterData.masterAttive"];
      }
    },
    masterScadute: {
      get() {
        return this.strings[this.globalLocale]["masterData.masterScadute"];
      }
    },
    labeWaiting: {
      get() {
        return this.strings[this.globalLocale]["upFormData.waiting"];
      }
    },
    labelElab: {
      get() {
        return this.strings[this.globalLocale]["upFormData.elab"];
      }
    },
    labelTitlePaginator: {
      get() {
        return this.strings[this.globalLocale]["upFormData.titlePaginator"];
      }
    },
    labelGestione: {
      get() {
        return this.strings[this.globalLocale]["upFormData.gestione"];
      }
    },
    labelFunz: {
      get() {
        return this.strings[this.globalLocale]["upFormData.funz"];
      }
    },
    labelCancella: {
      get() {
        return this.strings[this.globalLocale]["upFormData.canc"];
      }
    },
    labelScheda: {
      get() {
        return this.strings[this.globalLocale]["upFormData.scheda"];
      }
    },
    labelMod: {
      get() {
        return this.strings[this.globalLocale]["upFormData.mod"];
      }
    },
    labelConfirm: {
      get() {
        return this.strings[this.globalLocale]["upFormData.freeze"];
      }
    },
    labelDuplica: {
      get() {
        return this.strings[this.globalLocale]["upFormData.duplica"];
      }
    },
    labelRoc: {
      get() {
        return this.strings[this.globalLocale]["upFormData.roc"];
      }
    },
    labelProtezione: {
      get() {
        return this.strings[this.globalLocale]["upFormData.prot"];
      }
    },
    labelFito: {
      get() {
        return this.strings[this.globalLocale]["upFormData.fito"];
      }
    },
    labelInsetti: {
      get() {
        return this.strings[this.globalLocale]["upFormData.insetti"];
      }
    },
    labelMalattie: {
      get() {
        return this.strings[this.globalLocale]["upFormData.malattie"];
      }
    },
    labelFenologia: {
      get() {
        return this.strings[this.globalLocale]["upFormData.feno"];
      }
    },
    labelMeteo: {
      get() {
        return this.strings[this.globalLocale]["upFormData.meteo"];
      }
    },
    labelAvanti: {
      get() {
        return this.strings[this.globalLocale]["paginator.avanti"];
      }
    },
    labelIndietro: {
      get() {
        return this.strings[this.globalLocale]["paginator.indietro"];
      }
    },
    labelPrima: {
      get() {
        return this.strings[this.globalLocale]["paginator.prima"];
      }
    },
    labelTornaSu: {
      get() {
        return this.strings[this.globalLocale]["buttons.tornasu"];
      }
    },
    labelAccetto: {
      get() {
        return this.strings[this.globalLocale]["buttons.accetto"];
      }
    },
    labelUltima: {
      get() {
        return this.strings[this.globalLocale]["paginator.ultima"];
      }
    },
    labelTitle: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.title"];
      }
    },
    labelUtente: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.utente"];
      }
    },
    labelAzienda: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.azienda"];
      }
    },
    labelDescrizione: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.descrizione"];
      }
    },
    labelSuperficie: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.superficie"];
      }
    },
    labelNazione: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.nazione"];
      }
    },
    labelRegione: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.regione"];
      }
    },
    labelProvincia: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.provincia"];
      }
    },
    labelComune: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.comune"];
      }
    },
    labelStazione: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.stazione"];
      }
    },
    labelLatitudine: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.lat"];
      }
    },
    labelLongitudine: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.lng"];
      }
    },
    labelSistema: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.sisColt"];
      }
    },
    labelVarieta: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.var"];
      }
    },
    labelCautelaPE: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.cautelaPE"];
      }
    },
    labelPresenzaMAN: {
      get() {
        return this.strings[this.globalLocale]["viewUpData.presenzaMAN"];
      }
    },
    titleMap: {
      get() {
        return this.strings[this.globalLocale]["editUPData.titleMap"];
      }
    },
    labelErrorMsg: {
      get() {
        return this.strings[this.globalLocale]["editUPData.errorMsg"];
      }
    },
    labelOkDeleteMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.deleteOk"];
      }
    },
    labelErrorDeleteMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.deleteError"];
      }
    },
    labelErrorUPDeleteMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.deleteUPError"];
      }
    },
    labelOkSaveMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.saveOK"];
      }
    },
    labelErrorSaveMsg: {
      get() {
        return this.strings[this.globalLocale]["messages.saveError"];
      }
    },
    helpMap: {
      get() {
        return this.strings[this.globalLocale]["messages.helpMap"];
      }
    },
    labelWarn: {
      get() {
        return this.strings[this.globalLocale]["messages.warn"];
      }
    },
    labelDialogUPDelete: {
      get() {
        return this.strings[this.globalLocale]["messages.confirmDeleteUP"];
      }
    },
    labelDialogAziendaDelete: {
      get() {
        return this.strings[this.globalLocale]["messages.confirmDeleteAzienda"];
      }
    },
    labelDialogProdottoDelete: {
      get() {
        return this.strings[this.globalLocale][
          "messages.confirmDeleteProdotto"
        ];
      }
    },
    labelOkBtn: {
      get() {
        return this.strings[this.globalLocale]["buttons.confirmBtn"];
      }
    },
    labelCloseBtn: {
      get() {
        return this.strings[this.globalLocale]["buttons.closeBtn"];
      }
    },
    labelAnnulla: {
      get() {
        return this.strings[this.globalLocale]["buttons.undoBtn"];
      }
    },
    labelSalva: {
      get() {
        return this.strings[this.globalLocale]["buttons.saveBtn"];
      }
    },
    labelSi: {
      get() {
        return this.strings[this.globalLocale]["buttons.siBtn"];
      }
    },
    labelNo: {
      get() {
        return this.strings[this.globalLocale]["buttons.noBtn"];
      }
    },
    labelTitleEdit: {
      get() {
        return this.strings[this.globalLocale]["editUPData.titleEdit"];
      }
    },
    labelTitleNew: {
      get() {
        return this.strings[this.globalLocale]["editUPData.titleNew"];
      }
    },
    noItems: {
      get() {
        return this.strings[this.globalLocale]["messages.noItem"];
      }
    },
    noProducts: {
      get() {
        return this.strings[this.globalLocale]["messages.noProducts"];
      }
    },
    noUser: {
      get() {
        return this.strings[this.globalLocale]["messages.noUser"];
      }
    },
    noProvincia: {
      get() {
        return this.strings[this.globalLocale]["messages.noProvincia"];
      }
    },
    noRegione: {
      get() {
        return this.strings[this.globalLocale]["messages.noRegione"];
      }
    },
    noCountry: {
      get() {
        return this.strings[this.globalLocale]["messages.noCountry"];
      }
    },
    markerAlreadyOn: {
      get() {
        return this.strings[this.globalLocale]["messages.markerSet"];
      }
    },
    required: {
      get() {
        return this.strings[this.globalLocale]["messages.required"];
      }
    },
    nozero: {
      get() {
        return this.strings[this.globalLocale]["messages.nozero"];
      }
    },
    labelTitleFasifen: {
      get() {
        return this.strings[this.globalLocale]["fasiFenData.title"];
      }
    },
    labelEditFasifen: {
      get() {
        return this.strings[this.globalLocale]["fasiFenData.modDate"];
      }
    },
    labelFasifen: {
      get() {
        return this.strings[this.globalLocale]["fasiFenData.faseDes"];
      }
    },
    labelDataStimata: {
      get() {
        return this.strings[this.globalLocale]["fasiFenData.dataStimata"];
      }
    },
    labelDataOsservata: {
      get() {
        return this.strings[this.globalLocale]["fasiFenData.dataUtente"];
      }
    },
    labelLogin: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.login"];
      }
    },
    labelEnter: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.enter"];
      }
    },
    labelUsername: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.username"];
      }
    },
    labelPassword: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.password"];
      }
    },
    labelLang: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.lang"];
      }
    },
    labelIT: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.it"];
      }
    },
    labelEN: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.en"];
      }
    },
    labelES: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.es"];
      }
    },
    labelPT: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.pt"];
      }
    },
    labelTR: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.tr"];
      }
    },
    labelFR: {
      get() {
        return this.strings[this.globalLocale]["loginFormData.fr"];
      }
    }
  }
};
